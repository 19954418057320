import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Subject } from '../../../../node_modules/rxjs';
import { Observable } from 'rxjs/Observable';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from '../../../../node_modules/rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchterm: string;

  startAt = new Subject();
  endAt = new Subject();

  starobs = this.startAt.asObservable();
  endobs = this.endAt.asObservable();

  productsname;
  productsmodel;


  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    Observable.combineLatest(this.starobs, this.endobs).subscribe((value) => {
      this.firequeryname(value[0], value[1]).subscribe((products) => {
        // console.log(products)
        this.productsname = products;
      })
      this.firequerymodel(value[0], value[1]).subscribe((products) => {
        // console.log(products)
        this.productsmodel = products
      })
    })
    const queryParams = this.route.snapshot.queryParams;
    const routeParams = this.route.snapshot.params;
    console.log(queryParams, routeParams);

  }

  search($event) {
    let q = $event.target.value;
    this.startAt.next(q);
    this.endAt.next(q + '\uf8ff');
  }

  firequeryname(start, end) {
    return this.afs.collection('products', ref => ref.limit(4).orderBy('name').startAt(start).endAt(end)).valueChanges();
  }
  firequerymodel(start, end) {
    return this.afs.collection('products', ref => ref.limit(4).orderBy('model').startAt(start).endAt(end)).valueChanges();
  }

  gotoProd(ide) {
    // let actUrl = this.router.url;
    // if (ide != actUrl) {
    //   this.router.navigate(['/product', ide]).then(view => {
    //     console.log(this.router.url);
    //     this.router.ngOnDestroy();
    //   }).catch(err => {
    //     console.log(err);
    //   })
    // }
    

    this.router.navigate(['/nothi']).then(() => {
      this.router.navigate(['/product', ide]).then(() => {
        this.router.ngOnDestroy();
      }).catch(err => {
      })
    })
  }

  

  ngOnDestroy() {

  }


}

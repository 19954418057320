import { Injectable } from '@angular/core';
import { Http, Headers, Response, URLSearchParams } from '@angular/http';
// import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class SendmailService {

  constructor(
    private http: Http
  ) { }

  send(emailAddress, content) {
    const url = `https://us-central1-tienda-12498.cloudfunctions.net/httpEmail`;
    const params: URLSearchParams = new URLSearchParams();
    const headers = new Headers({
      'Content-Type' : 'application/json',
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Methods' : 'POST'
    });

    params.set('to', emailAddress);
    params.set('from', 'info@gpsenmexico.com');
    params.set('content', content);

    return this.http.post(url, params, {headers})
                 .toPromise()
                 .then( res => {
                   console.log (res);
                 })
                 .catch(err => {
                   console.log(err);
                 });
  }

}

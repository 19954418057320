import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AngularFirestore } from 'angularfire2/firestore';
import { Cart } from '../models/cart';
import { Product } from '../models/product';

@Injectable()
export class CartService {

  constructor(private afs: AngularFirestore,
              public auth: AuthService) { }

  createCart(id) {
    this.afs.collection('carts').doc(id).set(
      {id: id, products: [], totalProducts: 0}
    );
  }

  myCart(uid) {
    return this.afs.doc<Cart>(`carts/${uid}`).snapshotChanges();
  }

  myCartRef(uid) {
    return this.afs.collection<Cart>(`carts`).doc(uid).ref;
  }

  addProduct(product): Promise<any> {
    return new Promise ((resolve, reject) => {
      this.auth.user.subscribe(data => {
        if (data) {
          const ref = this.myCartRef(data.uid);
          ref.get().then(doc => {
            const cartData = doc.data();
            const productsInCart = cartData.products;
            const productToCart = {
              id: product.id,
              name: product.name,
              model: product.model,
              qty: 1,
              price: product.price,
              subtitle: product.subtitle,
              uid: product.uid,
              photo: product.uploads[0],
              shipment: product.shipment,
              selected: null,
              status: 'ordered'
            };
            const exists = CartService.findProductByKey(productsInCart, 'id', product.id);
            if ( !exists ) {
              productsInCart.push(productToCart);
              cartData.totalProducts += 1;
            } else {
              exists.qty += 1;
              cartData.totalProducts += 1;
            }
            return ref.update(cartData).then(() => {
              resolve(true);
            }).catch((err) => {
              reject(err);
            });
          });
        }

      });
    });

  }

  updateProduct(product, qty, uid): Promise<any> {
    return new Promise((resolve, reject) => {
      const ref = this.myCartRef(uid);
      ref.get().then(doc => {
        const cartData = doc.data();
        const productsInCart = cartData.products;
        const exists = CartService.findProductByKey(productsInCart, 'id', product.id);
        if (exists) {
          if (exists.qty !== qty) {
            exists.qty = qty;
            cartData.totalProducts = CartService.totalProductsInCart(cartData.products);
          }
          return ref.update(cartData).then(() => {
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  removeProduct(product, uid): Promise<any> {
    return new Promise((resolve, reject) => {
      const ref = this.myCartRef(uid);
      ref.get().then(doc => {
        const cartData = doc.data();
        const productsInCart = cartData.products;
        const totalQty = cartData.totalProducts;
        // tslint:disable-next-line:radix
        cartData.totalProducts = parseInt(totalQty) - parseInt(product.qty);

        const exists = CartService.findProductByKey(productsInCart, 'id', product.id);
        if (exists) {
          const index = productsInCart.findIndex(obj => obj.id === product.id);
          cartData.products = [
            ...productsInCart.slice(0, index),
            ...productsInCart.slice(index + 1)
          ];
          return ref.update(cartData).then(() => {
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  // tslint:disable-next-line:member-ordering
  static findProductByKey(array, key, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }

  // tslint:disable-next-line:member-ordering
  static totalProductsInCart(products: Product[]) {
    let sum = 0;
    for (let i = 0; i < products.length; i++) {
      // tslint:disable-next-line:radix
      sum += parseInt(products[i]['qty']);
    }
    return sum;
  }

  totalPrice(products: Product[]): number {
    let total = 0;
    for (let i = 0; i < products.length; i++) {
      // tslint:disable-next-line:radix
      total += (parseInt(products[i]['qty']) * products[i]['price']);
    }
    return total;
  }

  resetCart(uid): Promise<any> {
    const ref = this.myCartRef(uid);
    return ref.get().then(doc => {
      const cartData = doc.data();
      cartData.products = [];
      cartData.totalProducts = 0;
      return ref.update(cartData);
    });
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import * as faker from 'faker';
import { Product } from '../models/product';
import { UploadService } from '../admin/upload.service';
import { Observable ,  from as fromPromise, pipe } from 'rxjs';
import { expand, takeWhile, mergeMap, take } from 'rxjs/operators';
import { AngularFireDatabase } from 'angularfire2/database'

type productCollection = AngularFirestoreCollection<Product[]>;
type productDocument = AngularFirestoreDocument<Product>;

@Injectable()
export class ProductsService {

  constructor(
    private afs: AngularFirestore,
    private uploadService: UploadService,
    // private db: AngularFireDatabase
  ) { }

  prod() {
    return this.afs.collection<Product[]>('products');
  }

  products(property: string, filter: string) {
    if (!property) {
      return this.afs.collection<Product[]>('products', ref => ref.limit(10));

    // let first =  this.afs.collection<Product[]>('products').ref.limit(3);
    // return first.get().then(function(documentSnapshots) {
    //   let lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1]
    // })
    } else if ( property === 'subcategories' ) {
      return this.afs.collection<Product[]>('products', ref => ref.where(`${property}.${filter}`, '==', true) );
    } else if ( property === 'model' ) {
      return this.afs.collection<Product[]>('products', ref => ref.where(`${property}`, '==', filter) );
    }
  }
  productsFilter(property: string, filter: string) {
  }
  productsUid(id: string) {
    return this.afs.collection(`products`, ref => ref.where(`uid`, '==', id));
  }
  product(id: string): productDocument {
    return this.afs.doc<Product>(`products/${id}`);
  }

  save(product: Product): Promise<any> {
    product.id = faker.random.alphaNumeric(16);
    return this.prod().doc(product.id).set(Object.assign({}, product));
  }
  saveForm(product: Product): Promise<any> {
    // product.id = faker.random.alphaNumeric(16);
    return this.prod().doc(product.id).set(Object.assign({}, product));
  }

  update(product: Product): Promise<any> {
    return this.product(product.id).update(Object.assign({}, product));
  }

  getProductImages(productId: string) {
    return this.afs.doc<Product>(`products/${productId}`).collection('uploads');
  }

  remove(id): Promise<any> {
    const ref = this.product(id);
    ref.delete();
    return new Promise((resolve, reject) => {
      return ref.collection(`uploads`).valueChanges().subscribe((files) => {
        if (files) {
          this.deleteUploadsCollection(`products/${id}/uploads`, 1).subscribe(() => {
           ref.delete().then(() => {
             resolve(true);
           }).catch(error => {
             reject(error);
           });
         });
        } else {
          ref.delete().then(() => {
            resolve(true);
          }).catch(error => {
            reject(error);
          });
        }
      });
    });
  }

  private deleteUploadsCollection(path: string, limit: number): Observable<any> {
    const source = this.deleteBatch(path, limit);
    return source.pipe(
      expand(val => this.deleteBatch(path, limit)),
      takeWhile(val => val > 0)
    );
  }

  private deleteBatch(path: string, limit: number): Observable<any> {
    // tslint:disable-next-line:no-shadowed-variable
    const ref = this.afs.collection(path, ref => ref.orderBy('name').limit(limit));
    return ref.snapshotChanges().pipe(
      take(1),
      mergeMap(snapshot => {
        const batch = this.afs.firestore.batch();
        snapshot.forEach(doc => {
          this.uploadService.removeFile(doc.payload.doc.id);
          batch.delete(doc.payload.doc.ref);
        });
        return fromPromise(batch.commit()).map(() => snapshot.length);
      })
    );
  }

  // test get products array by subcategory
  getProductTest(category): productCollection {
    return this.afs.collection<Product[]>(`products`, ref => ref.where(`categories.${category.name}`, '==', true));
  }

  lastProducts() {
    const dateNow = new Date;
    return this.afs.collection<Product[]>('products', ref => ref.orderBy('created_at', 'asc').limit(3))

  }


  // remove(id): Promise<any> {
  //   const ref = this.product(id);
  //   return new Promise((resolve, reject) => {
  //     return ref.collection<Upload>('uploads').valueChanges().subscribe((files) => {
  //       if (files) {
  //         files.forEach((file: Upload) => {
  //           this.uploadService.removeFile(file.id).then(() => {
  //             this.afs.doc(`products/${id}/uploads/${file.id}`).delete();
  //           }).catch((error => {}));
  //         });
  //       }
  //       ref.delete().then(() => {
  //         resolve(true);
  //       }).catch(error => {
  //         reject(error);
  //       });
  //     });
  //   });
  // }

}

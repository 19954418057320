import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../../common/products.service';
import { AuthService } from '../../auth/auth.service';
import { Upload } from '../../models/upload';
import { CartService } from '../../common/cart.service';
import { Product } from '../../models/product';
import { SnackService } from '@common/snack.service';
import { AppService } from '@common/app.service';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  gif = '../../../assets/img/desliza.gif';

  public product: any;
  uploads: any;
  public slides = [];
  public slideConfig = {'slideToShow': 2, 'slideToScroll': 2};

  constructor(
    private route: ActivatedRoute,
    public productService: ProductsService,
    public auth: AuthService,
    public cartService: CartService,
    private snack: SnackService,
    private appService: AppService
  ) {
    this.appService.fireLoader();
    const id = this.route.snapshot.paramMap.get('id');
    const product = this.productService.product(id);
    product.collection('uploads').valueChanges().subscribe(uploadSnap => {
      this.uploads = uploadSnap;
      uploadSnap.map((upload: Upload) => {
        this.slides.push({img: upload.url});
      });
    });
    this.product = product.valueChanges();
    this.appService.stopLoader();
  }

  ngOnInit() {

  }

  addProduct(product) {
    product.uploads = this.uploads
    this.cartService.addProduct(product)
      .then(() => {
        this.snack.launch('Producto Agregado', 'Productos', 3000);
      })
      .catch((error) => {
        this.snack.launch('Error: ' + error.message, 'Productos', 3000);
      });
  }
  hover() {
    this.gif = '../../../assets/img/deslizainfi.gif';
  }
  leave() {
    this.gif = '../../../assets/img/swipegif.jpg'
  }

}

import { Component, OnInit } from '@angular/core';
import { Category } from '../../models/category';
import { CategoryService } from '@common/category.service';
import { Brand } from '../../models/brand';
import { BrandService } from '@common/brand.service';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.css']
})
export class MenuListComponent implements OnInit {
  categories: Category[] [];
  subcatFilter;
  brands: Brand[] [];

  constructor(private categoryService: CategoryService,
              private brandService: BrandService) {
    this.categoryService.categories().valueChanges().subscribe(data => {
      this.categories = data;
    })
    this.brandService.brands().valueChanges().subscribe(data => {
      this.brands = data;
    })
  }

  ngOnInit() {
  }
  selected(subcate: any) {
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { SnackService } from '../../common/snack.service';
import { AppService } from '@common/app.service';
import { MatDialogRef } from '@angular/material';

export class Auth {
  constructor(
    public email: string,
    public password: string
  ) {}
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public router: Router,
    private snack: SnackService,
    public appService: AppService,
    public dialogRef: MatDialogRef<LoginComponent>
  ) { }

  ngOnInit() {
  }

  login(user: Auth) {
    this.appService.fireLoader();
    this.auth.emailAndPassword(user.email, user.password).then(credentials => {
      this.router.navigate(['/explore']).then(() => {
        this.appService.stopLoader();
        this.dialogRef.close();
      }).catch(err => {
        this.snack.launch('Error: ' + err.message, 'La ruta no existe', 5000);
        this.appService.stopLoader();
      });
    })
    .catch(err => {
      this.snack.launch('Error: ' + err.message, 'Inicio de Sesión', 5000);
      this.appService.stopLoader();
    });
  }

  googleLogin() {
    this.auth.googleLogin().then(() => {
      this.dialogRef.close();
    })
  }

  twitterLogin() {
    this.auth.twitterLogin().then(() => {
      this.dialogRef.close();
    })
  }

  facebookLogin() {
    this.auth.facebookLogin().then(() => {
      this.dialogRef.close();
    })
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AdminModule } from './admin/admin.module';
import { ShopModule } from '@shop/shop.module';
import { FrontModule } from './front/front.module';

// Enviroments
import { environment } from '../environments/environment';

// Components
import { AppComponent } from './app.component';
import { NavigationComponent } from './common/navigation/navigation.component';
import { CartComponent } from '@shop/cart/cart.component';

// Pipes


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AuthModule,
    AdminModule,
    ShopModule,
    FrontModule,
  ],
  entryComponents: [CartComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

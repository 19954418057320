import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackService } from '@common/snack.service';
import { AuthService } from '@auth/auth.service';
import { OrdersService } from '../../common/orders.service';
import * as faker from 'faker';
import { AngularFirestore } from 'angularfire2/firestore';

@Component({
  selector: 'app-payorder-dialog',
  templateUrl: './payorder-dialog.component.html',
  styleUrls: ['./payorder-dialog.component.css']
})
export class PayorderDialogComponent implements OnInit {
  vaucher = {
    id: '',
    num: '',
    numvau: ''
  }

  constructor(
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<PayorderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snack: SnackService,
    public auth: AuthService,
    public orderService: OrdersService
  ) {
    if (this.data.num) {
      this.orderService.getReport(this.data.num).valueChanges().subscribe(data => {
        for (let vauc of data) {
          console.log(vauc);
          this.vaucher.id = vauc['id'];
          this.vaucher.numvau = vauc['numvau'];
        }
      })
    }
  }

  ngOnInit() {
  }

  saverReport() {
    this.vaucher.num = this.data.num;

    if (!this.vaucher.id) {
      this.orderService.saveReportpay(this.vaucher).then(() => {
        this.snack.launch('Su reporte fue enviado, en breve actualizaremos el estatus del pago', 'Pedidos', 7000);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Pedidos', 4000);
      })
    } else  {
      this.orderService.updateReportpay(this.vaucher).then(() => {
        this.snack.launch('Reporte de Pago Actualizado', 'Pedidos', 4000);
      });
    }
    this.dialogRef.close();

  }

}

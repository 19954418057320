import { Map } from './map';

export class Product {
    id?: string;
    name: string;
    price: number;
    description: string;
    ref?: string;
    subtitle: string;
    created_at: Date = new Date;
    uid: string;
    categories: any;
    brand: {
        id: string;
        name: string;
    };
    model: string;
    shipment?: {
        nameship?: string;
        pay?: number;
    };
    stock: number;
    espec: string;
}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Category } from '../models/category';
import * as faker from 'faker';
import { Subcategory } from '../models/subcategory';
import { Observable ,  from as fromPromise } from 'rxjs';
import { expand, takeWhile, take, mergeMap } from 'rxjs/operators';
import { SubcategoryService } from './subcategory.service';

type categoryCollection = AngularFirestoreCollection<Category[]>;
type categoryDocument = AngularFirestoreDocument<Category>;


@Injectable()
export class CategoryService {


  constructor(
    private afs: AngularFirestore,
    private subcategoryService: SubcategoryService

  ) { }

  categories() {
    return this.afs.collection<Category[]>('categories');
  }
  category(id: string): categoryDocument {
    return this.afs.doc<Category>(`categories/${id}`);
  }

  save(category: Category): Promise<any> {
    category.id = faker.random.alphaNumeric(16);
    return this.afs.collection<Category>('categories').doc(category.id).set(Object.assign({}, category));
  }

  update(category: Category): Promise<any> {
    return this.category(category.id).update(Object.assign({}, category));
  }

  private saveSubcat(subcategory: Subcategory, id) {
    const category = this.afs.collection('categories').doc(id);
    const newRef = category.collection('subcategories').doc(subcategory.id);
    newRef.set({
      id: subcategory.id,
      name: subcategory.name,
    });

  }

  removeCategory(id: string): Promise<any> {
    const ref = this.category(id);
    ref.delete();
    return this.subcategoryService.removeParentSubcategory(id);
    // return new Promise((resolve, reject) => {
    //   this.deleteSubcategoriesCollection(`categories/${id}/subcategories`, 10).subscribe(() => {
    //     ref.delete().then(() => {
    //       resolve(true);
    //     }).catch(error => {
    //       reject(error);
    //     });
    //   });
    // });
  }

}

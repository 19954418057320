import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopRoutingModule } from './shop-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SlickModule } from 'ngx-slick';
import { HttpModule } from '@angular/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Components
import { SidebarComponent } from './sidebar/sidebar.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './product/product.component';
import { OrdersComponent } from './orders/orders.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CartComponent } from '@shop/cart/cart.component';
import { ShipmentDialogComponent } from './checkout/shipment-dialog/shipment-dialog.component';
import { MenuSideComponent } from './menu-side/menu-side.component';
import { MenuListComponent } from './menu-list/menu-list.component';
import { PayorderDialogComponent } from './orders/payorder-dialog.component';
import { ProductsFilterComponent } from './products-filter/products-filter.component';
import { SearchComponent } from './search/search.component';

// Service
import { CheckoutService } from '../common/checkout.service';
import { SendmailService } from '@common/sendmail.service';
import { MenuDataService } from '../common/menu-data.service';

// Directives
import { ScrollableDirective } from '../directives/scrollable.directive';
import { HelpComponent } from './help/help.component';

@NgModule({
  imports: [
    CommonModule,
    ShopRoutingModule,
    SharedModule,
    SlickModule.forRoot(),
    HttpModule,
    InfiniteScrollModule
  ],
  declarations: [
    ProductsComponent,
    ProductComponent,
    CartComponent,
    OrdersComponent,
    SidebarComponent,
    CheckoutComponent,
    ShipmentDialogComponent,
    MenuSideComponent,
    MenuListComponent,
    PayorderDialogComponent,
    ProductsFilterComponent,
    ScrollableDirective,
    SearchComponent,
    HelpComponent
  ],
  entryComponents: [ShipmentDialogComponent, PayorderDialogComponent],
  exports: [CartComponent, OrdersComponent, SearchComponent],
  providers: [CheckoutService, SendmailService, MenuDataService]
})
export class ShopModule { }

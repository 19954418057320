import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Address } from '../models/address';
import * as faker from 'faker';

type addressDocument = AngularFirestoreDocument<Address>;
type addressCollection = AngularFirestoreCollection<Address>;

@Injectable()
export class AddressService {
  country: any;

  constructor(
    private afs: AngularFirestore
  ) { }

  getAddress(addressUid: string): addressCollection {

    return this.afs.collection(`address`, ref => ref.where(`uid`, '==', addressUid));
  }

  addAddress(address: Address): Promise<any> {
    address.id = faker.random.alphaNumeric(16);
    return this.afs.collection<Address>('address').doc(address.id).set(Object.assign({}, address));
  }
  updateAddress(address: Address): Promise<any> {
    return this.afs.collection<Address>('address').doc(address.id).update(address);
  }

  getCountries() {
    return this.afs.collection(`country`);
  }

  getCountry(countryName) {
    return this.afs.collection(`country`, ref => ref.where(`name`, '==', countryName));
  }
  getStates(countryData) {
    return this.afs.collection(`country`).doc(countryData.id).collection(`states`, ref => ref.orderBy('name'));
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { OrdersService } from '../../common/orders.service';
import { MatTableDataSource, MatSort, MatDialog, MatDialogConfig, MatPaginator } from '@angular/material';
import { SnackService } from '@common/snack.service';
import { OrdersadmDialogComponent } from './ordersadm-dialog.component';
import { OrderpayDialogComponent } from './orderpay-dialog.component';

@Component({
  selector: 'app-ordersadm',
  templateUrl: './ordersadm.component.html',
  styleUrls: ['./ordersadm.component.css']
})
export class OrdersadmComponent implements OnInit {
  datas = [{
    num: null
  }];

  displayedColumns = ['num', 'date', 'name', 'shipment', 'status', 'pay'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) csort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public auth: AuthService,
    public orderService: OrdersService,
    private snack: SnackService,
    public dialog: MatDialog
  ) {
    this.auth.user.subscribe(data => {
      this.orderService.orderUnit(data.uid).valueChanges().subscribe(dataOrd => {
        // this.datas = dataOrd.num;
        this.dataSource = new MatTableDataSource(dataOrd);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.csort;
      });
    });
  }

  ngOnInit() {
  }

  changeStatus(order) {
    this.dialog.open(OrdersadmDialogComponent, OrdersadmComponent.dialogConfig(order));
  }
  changePay(order) {
    this.dialog.open(OrderpayDialogComponent, OrdersadmComponent.dialogConfig(order))

  }

  // tslint:disable-next-line:member-ordering
  private static dialogConfig(data): MatDialogConfig {
    const config: MatDialogConfig = new MatDialogConfig;
    config.width = '700px';
    config.data = data;
    return config;
  }

}

import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { AppService } from '@common/app.service';
import { Router } from '@angular/router';
import { SnackService } from '@common/snack.service';
import { ProductsService } from '@common/products.service';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { RegisterComponent } from '../../auth/register/register.component';
import { IImage } from 'ng-simple-slideshow/src/app/modules/slideshow/IImage';
import { NguCarousel, NguCarouselStore } from '@ngu/carousel';

export class Auth {

  constructor(
    public email: string,
    public password: string
  ) {}
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public owlOpt: any = {
    nav:true,
    items:2,
    responsiveClass: true,
    responsive: {
      '0': {
        items: 1,
        nav: true
      },
      '600': {
        items: 3,
        nav: true
      },
      '100': {
        items: 5,
        nav: true,
        loop: false
      }
    }
  }
  public carouselBrand: NguCarousel;
  images: Array<string> = [
    'garmin1.png',
    'tascam1.png',
    'ghost1.png',
    'vidpro1.png'
  ]
  imageUrlArray: Array<string> = [
    'product/gaxzqro97fzzfsiz'
  ]
  slider: IImage[] = [{
    url: 'assets/img/pruebaslide6.jpg',
    href: '/product/gaxzqro97fzzfsiz',
    title: 'Forerunner 645',
    clickAction: this.navigate
  },
  {
    url: 'assets/img/pruebaslide7.jpg',
    href: '/product/gaxzqro97fzzfsiz',
    title: 'Vivoactive 3 Music'
  }
  ]
    // 'assets/img/pruebaslide5.jpg',
    // 'assets/img/pruebaslide7.jpg'
    // 'assets/img/prueba4.png'

  public products: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth
    this.innerHeight = window.innerHeight
  }
  innerWidth: any;
  innerHeight: any;

  constructor(
    private auth: AuthService,
    private appService: AppService,
    public router: Router,
    public snack: SnackService,
    private productService: ProductsService,
    public dialog: MatDialog,

  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight - 300;
    this.lastProducts();
    this.carouselBrand = {
      grid: {xs: 1, sm: 1, md: 2, lg: 3, all: 0},
      slide: 2,
      speed: 400,
      interval: 4000,
      point: {
        visible: false,
        pointStyles: `
          .ngucarouselPoint {
            list-style-type: none;
            text-align: center;
            padding: 12px;
            margin: 0;
            white-space: nowrap;
            overflow: auto;
            position: absolute;
            width: 100%;
            bottom: 20px;
            left: 0;
            box-sizing: border-box;
          }
          .ngucarouselPoint li {
            display: inline-block;
            border-radius: 999px;
            background: rgba(255, 255, 255, 0.55);
            padding: 5px;
            margin: 0 3px;
            transition: .4s ease all;
          }
          .ngucarouselPoint li.active {
              background: white;
              width: 10px;
          }
        `
      },
      load: 2,
      touch: true,
      loop: true,
      custom: 'banner'
    }
  }

  navigate() {
  }
  public myfunc(event: Event) {
    console.log(event);
  }
  onmoveFn(data: NguCarouselStore) {
    console.log(data);
  }

  login(user: Auth) {
    this.appService.fireLoader();
    this.auth.emailAndPassword(user.email, user.password).then(credentials => {
      this.router.navigate(['/explore']).then(() => {
        this.appService.stopLoader();
      }).catch(err => {
        this.snack.launch('Error: ' + err.message, 'La ruta no existe', 5000);
        this.appService.stopLoader();
      });
    })
    .catch(err => {
      this.snack.launch('Error: ' + err.message, 'Inicio de Sesión', 5000);
      this.appService.stopLoader();
    });
  }

  lastProducts() {
    this.products = this.productService.lastProducts().snapshotChanges().map(productSnapshot => {
      
      return productSnapshot.map(product => {
        const productData = product.payload.doc.data();
        const productId = product.payload.doc.id;
        return this.productService.getProductImages(productId).snapshotChanges().map(uploadSnap => {
          let number = 0;
          return uploadSnap.map(upload => {
            if (number === 0) {
              number++;
              return upload.payload.doc.data();
            }
          })
        })
        .map(uploads => {
          return {productId, ...productData, uploads: uploads};
        })
      })
    })
    .flatMap(products => Observable.combineLatest(products))
  }

  registerDia() {
    this.dialog.open(RegisterComponent, HomeComponent.dialogConfig())
  }

  private static dialogConfig(): MatDialogConfig {
    const config: MatDialogConfig = new MatDialogConfig;
    config.width = '400px';
    config.panelClass = 'custom-container';
    return config;
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { User } from 'firebase/app';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile: any;
  updateFile: File;

  constructor(
    public auth: AuthService,
    private atr: ActivatedRoute
  ) {
    this.auth.user.subscribe(data => {
      this.profile = data;
    });
    this.atr.params.subscribe(parametros => {

    });
  }

  ngOnInit() {
  }

  updatePhoto(event) {
    this.updateFile = event.target.files;
  }

}

import { Injectable } from '@angular/core';
import { Brand } from '../models/brand';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import * as faker from 'faker';
import { Product } from '../models/product';

type brandDocument = AngularFirestoreDocument<Brand>;
type brandCollection = AngularFirestoreCollection<Brand[]>;

@Injectable()
export class BrandService {

  constructor(private afs: AngularFirestore) { }

  brands() {
    return this.afs.collection<Brand[]>('brands');
  }
  brandsFilter(property, filter) {
    return this.afs.collection<Product[]>('products', ref => ref.where(property, '==', filter));
  }
  brand(id: string): brandDocument {
    return this.afs.doc<Brand>(`brands/${id}`);
  }

  update(brand: Brand): Promise<any> {
    return this.brand(brand.id).update(Object.assign({}, brand));
  }

  save(brand: Brand): Promise<any> {
    brand.id = faker.random.alphaNumeric(16);
    return this.afs.collection<Brand>('brands').doc(brand.id).set(Object.assign({}, brand));
  }

  getEditdBrands(brand) {
    let brandObj: string;
    if (brand) {
      for (const k of Object.keys(brand)) {
         brandObj = k;
      }
    }
    return this.afs.collection('brands', ref => ref.where('name', '==', brandObj));
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { CartService } from '../../common/cart.service';
import { Cart } from '../../models/cart';
import { Order } from '../../models/order';
import { Product } from '../../models/product';
import { SnackService } from '@common/snack.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppService } from '@common/app.service';
import { OrdersService } from '../../common/orders.service';
import { Observable } from 'rxjs';

declare const moment: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  cart: any;
  totalPrice: number;
  uid: string;
  numData;
  param$: Observable<any>;
  private where: any;

  constructor(
    public auth: AuthService,
    public cartService: CartService,
    public snack: SnackService,
    private router: Router,
    public appService: AppService,
    private orderService: OrdersService,
    private atr: ActivatedRoute
  ) {
    this.getNum();
    this.auth.user.subscribe(user => {
      if (user) {
        this.cartService.myCart(user.uid).subscribe(cart => {
          this.cart = cart.payload.data();
          this.totalPrice = this.cartService.totalPrice(this.cart.products);
          this.uid = user.uid;
        });
      }
    });
  }

  update(product: Product, qty) {
    this.appService.fireLoader();
    this.cartService.updateProduct(product, qty.value, this.uid).then(() => {
      this.snack.launch('Producto actualizado', 'Carrito', 4000);
      this.appService.stopLoader();
    })
    .catch(() => {
      this.snack.launch('Error actualizando el producto', 'Carrito', 4000);
      this.appService.stopLoader();
    });
  }

  remove(product: Product) {
    this.appService.fireLoader();
    this.cartService.removeProduct(product, this.uid).then(() => {
      this.snack.launch('Producto eliminado', 'Carrito', 4000);
      this.appService.stopLoader();
    })
    .catch(() => {
      this.snack.launch('Error eliminando el producto', 'Carrito', 4000);
      this.appService.stopLoader();
    });
  }

  ngOnInit() {
  }

  processOrder() {
    const order: Order = {
      id: null,
      customerId: this.uid,
      products: this.cart.products,
      amount: this.totalPrice,
      totalProducts: this.cart.totalProducts,
      created_at: moment(new Date).format('DD/MM/YYYY'),
      num: this.numData,
      pay: null,
      paypal: null,
      deposit: null
    };

    if (this.cart.products) {
      for (const product of this.cart.products) {
        const productLocal = product;
        this.orderService.stockUpdate(productLocal);
      }
    }

    this.orderService.save(order).then(() => {
      this.cartService.resetCart(this.uid).then(() => {
        this.snack.launch('Carrito eliminado y pedido creado', 'Carrito', 6000);
        this.router.navigate(['/explore']);
      });
    });

  }

  getNum() {
    const ref = this.orderService.setNumOrder();
    ref.get().then( data => {
      const newData = data.data();
      this.numData = newData.value;

    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AngularFirestore } from 'angularfire2/firestore';
import { AuthService } from '@auth/auth.service';
import { ProductsService } from '@common/products.service';
import { SnackService } from '@common/snack.service';
import { Product } from '../../models/product';
import { inject } from '@angular/core/testing';
import { Upload } from '../../models/upload';
import { UploadService } from '../upload.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CategoryService } from '@common/category.service';
import { Category } from '../../models/category';
import { forEach } from '@angular/router/src/utils/collection';
import { Subcategory } from '../../models/subcategory';
import { BrandService } from '../../common/brand.service';
import { Brand } from '../../models/brand';
import { ModelService } from '../../common/model.service';
import * as faker from 'faker';
import { SubcatPipe } from '../../pipes/subcat.pipe';


@Component({
  selector: 'app-products-dialog',
  templateUrl: './products-dialog.component.html',
  styleUrls: ['./products-dialog.component.css']
})
export class ProductsDialogComponent implements OnInit {

  // config Ngx-Editor
  htmlContent: '';

  editorConfig = {
    editable: true,
    spellcheck: false,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Type something. Test the Editor... ヽ(^。^)丿',
    translate: 'no'
  };


  categories: any;
  brands: any;
  brandAll: any;
  brand: {
    id: string,
    name: string
  };
  models: any;
  subcats: any;
  subcates: Subcategory = {
    name: 'Running'
  };
  objectKeys = Object.keys;
  map: {[key: string]: boolean} = {};
  products: any;
  category: Category;
  shipments: Array<string> = ['Fedex', 'Estafeta', 'Zoom'];
  shipment: string;
  shipmentPay: number;
  shipmentsData: {
    name: string;
    pay: number;
  };

  product: Product = {
    id: '',
    uid: '',
    name: '',
    model: '',
    brand: null,
    shipment: {
      nameship: '',
      pay: null
    },
    categories: '',
    description: '',
    price: null,
    ref: '',
    subtitle: '',
    created_at: new Date,
    stock: null,
    espec: ''
  };

  uploads;
  uid: string;
  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  constructor(
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<ProductsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Product,
    private snack: SnackService,
    public auth: AuthService,
    public categoryService: CategoryService,
    private productService: ProductsService,
    private uploadService: UploadService,
    private _formBuilder: FormBuilder,
    private brandService: BrandService,
    private modelService: ModelService
  ) {

    if (data.id) {
      this.product = data;
        this.uploads = this.productService.product(this.data.id).collection('uploads').snapshotChanges().map(actions => {
          return actions.map(upload => {
            // tslint:disable-next-line:no-shadowed-variable
            const data = upload.payload.doc.data();
            const id = upload.payload.doc.id;
            return {id, ...data};
          });
        });
      } else {
        this.data = this.product;
    }

    this.auth.user.subscribe(user => {
      if (user) {
        this.uid = user.uid;
        this.data.uid = this.uid;
      }
    });
    // New
    this.brandService.brands().valueChanges().subscribe(brands => {
      this.brands = brands;
    });
    this.categoryService.categories().valueChanges().subscribe(categories => {
      this.categories = categories;

    });

  } // End constructor

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      id: new FormControl(this.data.id, Validators.required),
      uid: new FormControl(this.data.uid, Validators.required),

      name: new FormControl(this.data.name, [
                                  Validators.required
                                ]),
      ref: new FormControl(this.data.ref, [
                                  Validators.required
                                ]),
      price: new FormControl(this.data.price, [
                                  Validators.required,
                                  Validators.max(99999)
                                ]),
      subtitle: new FormControl(this.data.subtitle, [
                                      Validators.required
                                    ]),
      categories: new FormControl(),
      subcategories: new FormControl(),
      created_at: this.data.created_at,
      description: new FormControl(this.data.description, [
                                          Validators.required
                                        ]),
      espec: new FormControl(this.data.espec, [
                                          Validators.required
                                        ]),
      // brand: ['', Validators.required],
      brand: new FormControl(this.data.brand, Validators.required),
      model: new FormControl(this.data.model, [Validators.required]),
      stock: new FormControl(this.data.stock, [
                                                Validators.required,
                                                Validators.max(999)
                                              ]),
      // qty: new FormControl(null, [
      //                                           Validators.required,
      //                                           Validators.max(99)
      //                                         ]),
      shipment: new FormGroup({
        nameship: new FormControl(this.data.shipment.nameship, Validators.required),
        pay: new FormControl(this.data.shipment.pay, [
                                                      Validators.required,
                                                      Validators.max(99999)
                                                    ])

      })
    });
    this.onChangesBrand();
  }

  onChangesBrand() {
    this.firstFormGroup.get('brand').valueChanges.subscribe(camb => {
      if (camb) {
        this.getModelsOfBrand(camb);
      }
    });
  }


  saveProduct() {
    if (this.data.id) {
      this.brand = {
        id: this.data.brand.id,
        name: this.data.brand.name
      };
      if (this.data.categories) {
        const map: {[key: string]: boolean} = {};
        for (let subcategory of this.data.categories) {
          map[subcategory] = true
        }
        let arr = new Object;
        arr = new Object(map)
        this.firstFormGroup.controls['subcategories'].setValue(arr);
      }
      this.firstFormGroup.controls['categories'].reset();
      this.firstFormGroup.controls['brand'].reset();
      this.firstFormGroup.controls['brand'].setValue(this.brand);
      // this.firstFormGroup.controls['name'].
      this.productService.update(this.firstFormGroup.value).then(() => {
        this.snack.launch('Producto actualizado', 'Tienda', 4000);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Tienda', 4000);
      });
    } else {
      const newId = faker.random.alphaNumeric(16);
      this.firstFormGroup.controls['id'].setValue(newId);
      const map: {[key: string]: boolean} = {};
      let arr = new Object;
      // if (this.data.categories) {
      //   for (let category of this.data.categories) {
      //     map[category] = true;
      //   }
      //   arr = map
      //     this.data.categories = map
      //   }
      //   this.firstFormGroup.controls['categories'].setValue(arr)
      // (<FormArray>this.firstFormGroup.controls['categories']).push(arr)
      // this.firstFormGroup.controls['categories'].setValue(arr)
      // this.firstFormGroup.controls['categories'].setValue(map);
      // this.categoriesConve(this.firstFormGroup.controls['categories'])
      if (this.data.categories) {
        for (let subcategory of this.data.categories) {
          map[subcategory] = true
        }
        arr = new Object(map)
        this.firstFormGroup.controls['subcategories'].setValue(arr);
      }
      this.firstFormGroup.controls['categories'].reset();

      this.brand = {
        id: this.brandAll['id'],
        name: this.brandAll['name']
      };
      this.firstFormGroup.controls['uid'].setValue(this.uid);

      this.firstFormGroup.controls['brand'].reset();
      this.firstFormGroup.controls['brand'].setValue(this.brand);
      this.firstFormGroup.controls['description'].setValue(this.data.description);
      this.firstFormGroup.controls['espec'].setValue(this.data.espec);
            //    .controls['brand'].reset();
            // this.firstFormGroup.controls['brand'].setValue({
              //   id: this.brand.id,
              //   name: this.brand.name
              // });
              // const shipment: {[key: string]: number} = {};
              // shipment[this.shipment] = this.shipmentPay;
              //  this.data.brand.id = this.brand.id;
    //  this.data.brand.name = this.brand.name;
    // this.data.shipment = this.shipmentsData;
      this.productService.saveForm(this.firstFormGroup.value).then(() => {
        this.snack.launch('Producto creado', 'Tienda', 4000);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Tienda', 4000);
      });
    }
    this.dialogRef.close();
  }

  removeUpload(upload: Upload) {
    this.uploadService.removeFile(upload.id).then(() => {
      this.afs.doc(`products/${this.data.id}/uploads/${upload.id}`).delete().then(() => {
        this.snack.launch('Archivo Eliminado', 'Tienda', 4000);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Tienda', 4000);
      });
    });
  }

  getTest(category) {
    this.productService.getProductTest(category).valueChanges().subscribe(datos => {
      return this.products = datos;
    });
    return;
  }

  // Get the models select by brand select
  getModelsOfBrand(brand) {
    this.modelService.getBrandModels(brand.id).valueChanges().subscribe(datos => {
      return this.models = datos;
    });
    return;
  }

  // Change the brand to key boolean
  // mapBrand(brand) {
  //   const br: {[key: string]: boolean} = {};
  //   br[brand.name] = true;
  //   this.data.brand = br;
  // }

  // Change the categories to key boolean
  categoriesConve(categories) {
    if (categories > 1) {
      for (const key of categories) {
        this.map[key] = true;
        this.data.categories = new Array(this.map);
      }
    }
    this.firstFormGroup.controls['categories'].setValue(this.data.categories);
    return;
  }

  // getBrandsEdit(brand) {
  //     this.brandService.getEditdBrands(brand).valueChanges().subscribe(datos => {
  //       return this.data.brand = datos;
  //     });
  // }

}





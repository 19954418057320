import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Product } from '../../models/product';
import { SnackService } from '../../common/snack.service';
import { MatSort, MatTableDataSource, MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { OrdersService } from '../../common/orders.service';
import { Order } from '../../models/order';
import { UserService } from '../../common/user.service';
import { User } from 'firebase/app';
import * as firebase from 'firebase/app';
import { PayorderDialogComponent } from '@shop/orders/payorder-dialog.component';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent {
  orders: Order[][] = [];
  storage: any;


  displayedColumns = ['id', 'created_at', 'amount', 'totalProducts', 'detail'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private ordersService: OrdersService,
    private snack: SnackService,
    public auth: AuthService,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    this.auth.user.subscribe(user => {
      if ( user) {
        this.ordersService.orders(user.uid).valueChanges().subscribe(
          data => {
            this.orders = data;
            if (this.orders) {
              for (const order of this.orders) {
                if (order) {
                  console.log(order);
                  for (const product of order['products']) {
                    this.userService.user(product.uid).valueChanges().subscribe(store => {
                      this.storage = store;
                    });
                  }
                }
                // const products = this.orders['products'];
                // const storageId = products['uid'];
                // console.log(storageId);

              }
            }

            // this.dataSource = new MatTableDataSource(data);
            // this.dataSource.sort = this.sort;
          },
          err => {
            this.snack.launch('Error obteniendo pedidos: ' + err.message, 'Pedidos', 5000);
          }
        );
      }
    });
  }



  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.dataSource.filter = filterValue;
  }

  trackById(index, order: Order) {
    return order.id;
  }

  paydialog(order) {
    this.dialog.open(PayorderDialogComponent, OrdersComponent.dialogConfig(order));
  }

  private static dialogConfig(data): MatDialogConfig {
    const config: MatDialogConfig = new MatDialogConfig;
    config.width = '700px';
    config.data = data;
    return config;
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Brand } from '../../models/brand';
import { SnackService } from '@common/snack.service';
import { AuthService } from '@auth/auth.service';
import { BrandService } from '@common/brand.service';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.css']
})
export class BrandComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<BrandComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Brand,
    private snack: SnackService,
    public auth: AuthService,
    public brandService: BrandService
  ) { }

  ngOnInit() {
  }

  saveBrand() {
    if (this.data.id) {
      this.brandService.update(this.data).then(() => {
        this.snack.launch('Marca actualizada', 'Marcas', 4000);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Marcas', 4000);
      });
    } else {
      this.brandService.save(this.data).then(() => {
        this.snack.launch('Marca creada', 'Marcas', 4000);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Marcas', 4000);
      });
    }
    this.dialogRef.close();
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Model } from '../models/model';
import * as faker from 'faker';
import { Brand } from '../models/brand';

@Injectable()
export class ModelService {

  constructor(
    private afs: AngularFirestore
  ) { }

  // models(id: string) {
  //   return this.afs.collection(`models`)
  // }

  getBrandModels(id: string) {
    return this.afs.collection('models', ref => ref.where('brandId', '==', id));
  }
  getEditdModels(model) {
    return this.afs.collection('models', ref => ref.where(model, '==', true));
  }

  saveModel(model: Model): Promise<any> {
    model.id = faker.random.alphaNumeric(16);
    return this.afs.collection<Model[]>(`models`).doc(model.id)
    .set(Object.assign({}, model)).then(() => {
      // this.afs.collection(`categories`).doc(subcategory.categoryId).set(Object.assign({}, subcategory));
    });

  }
  brandUpdateModel(brand) {
    return this.afs.collection(`brands`).doc(brand.id).update(Object.assign({}, brand));
  }

  removeModel(model): Promise<any> {
      return new Promise((resolve, reject) => {
        // remove from Subcategories collection
        this.afs.collection(`models`).doc(model.id).delete();
        // remove from Categories.subategories array
        const modelOnBrand = this.afs.collection<Brand>(`brands`).doc(model.brandId).ref;
        modelOnBrand.get().then(doc => {
          const arrayData = doc.data();
          const modelOnData = arrayData.models;
          const exist = ModelService.findModelArray(modelOnData, model.name, true);
          if (exist) {
            const index = modelOnData.indexOf(exist);
            arrayData.models.splice(index, 1);
            return modelOnBrand.update(arrayData)
            .then(() => {
              resolve(true);
            }).catch((err) => {
              reject(err);
            });
          }
        });
      });
    }

    // tslint:disable-next-line:member-ordering
    static findModelArray(array, key, value) {
      for (let i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
          return array[i];
        }
      }
      return null;
    }

}

import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable()
export class CheckoutService {

  constructor(private afs: AngularFirestore) { }

  cartData(uid: string) {
    return this.afs.collection(`carts`).doc(uid);
  }

}

import { AppService } from '../../common/app.service';
import { Component, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { AuthService } from '@auth/auth.service';
import { OrdersService } from '../../common/orders.service';
import { AddressService } from '../../common/address.service';
import { User } from '../../models/user';
import { Address } from '../../models/address';
import { CartService } from '../../common/cart.service';
import { Order } from '../../models/order';
import { SnackService } from '@common/snack.service';
import { Router } from '@angular/router';
import { MatTableDataSource, MatSort, MatDialog, MatDialogConfig } from '@angular/material';
import { Cart } from '../../models/cart';
import { CheckoutService } from '../../common/checkout.service';
import { Bill } from '../../models/bill';
import { ShipmentDialogComponent } from '@shop/checkout/shipment-dialog/shipment-dialog.component';
import { Send } from '../../models/send';
import { Product } from '../../models/product';
import * as faker from 'faker';
import { SendmailService } from '../../common/sendmail.service';


declare const moment: any;
declare let paypal: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, AfterViewChecked {
  selectedShipment: [{
    id: '',
    nameship: '',
    pay: ''
  }];
  user: any;
  
  datas;
  displayedColumns = ['img', 'name', 'cant', 'price', 'shipment', 'selected'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) csort: MatSort;

  customer = {
    name: '',
    email: '',
    phone: '',
    address: false
  };
  cart: any;
  totalPrice: number;
  address: Address = {
    id: null,
    uid: '',
    address: '',
    city: '',
    state: '',
    country: ''
  };
  uid: string;
  countries: any;
  states: any;
  adrAct: Boolean = true;
  payment: String = 'paypal';
  numData;
  selected: any;
  oldData;

  formCheckout: FormGroup;
  
  // Paypal Properties
  paypalLoad: boolean = false;
  addScript: boolean = false;
  finalAmount: number = 0;
  paypalConfig = {
    env: 'sandbox',
    client: {
      sandbox: 'Ad255jpvVtk80_Gppp_k1PzCS4g4yjsC57BGB2HVvUzueKoGpYOQKPXazmKSqPtiRd9qQnmtPxELnAts',
      production: 'AUflbu9yxb6Dh8qAdLJcTeB-nC7T9jamJEto8M4lbhbFOqxj4LvIOT6qfAcdRngZfFI3IdgJPlsZRORS'
    },
    commit: true,
    payment: (data, actions) => {
      return actions.payment.create({
        payment: {
          transactions: [
            {amount: {total: this.finalAmount, currency: 'MXN'}}
          ]
        }
      });
    },
    onAuthorize: (data, actions) => {
      return actions.payment.execute().then((payment) => {
        this.formCheckout.controls['pay'].setValue('approved')
        this.formCheckout.controls['deposit'].setValue(false)
        this.formCheckout.controls['paypal'].setValue(true)
        this.processOrder(this.formCheckout.value)        
      })
    }
  }

  constructor( public auth: AuthService,
               private _formBuilder: FormBuilder,
               private orderService: OrdersService,
               private _addresService: AddressService,
               private cartService: CartService,
               public snack: SnackService,
               private router: Router,
               private chekoutService: CheckoutService,
               public appService: AppService,
               public dialog: MatDialog,
               public _sendMail: SendmailService
              ) {

                this.appService.fireLoader();

                this.auth.user.subscribe(user => {
                  this.uid = user.uid;
                    // this.customer = {
                    //   name: user.displayName,
                    //   email: user.email,
                    //   phone: user.phone,
                    //   address: user.address
                    // };
                  if (user.address !== false) {
                    this._addresService.getAddress(this.uid).valueChanges().subscribe(address => {
                      address.map(adr => {
                        this.address = adr;
                        this.formCheckout.patchValue({
                          address: adr.address,
                          city: adr.city,
                          country: adr.country,
                          state: adr.state
                        });
                      });
                    });
                  }
                  this.formCheckout.patchValue({
                    name: user.displayName,
                    email: user.email,
                    phone: user.phone,
                    address: user.address
                  });
                  // this.formCheckout.setValue(this.customer);
                  this.cartData(this.uid);
                  this.productData(this.uid);
                });
                this.appService.stopLoader();
                this.loadCountry();


                this.getNum();

                this.formCheckout = this._formBuilder.group({
                  name: new FormControl('', Validators.required),
                  email: new FormControl({value: '', disabled: true}, Validators.required),
                  phone: new FormControl('', Validators.required),
                  adrAct: new FormControl(true, Validators.required),
                  address: new FormControl('', Validators.required),
                  city: new FormControl('', Validators.required),
                  state: new FormControl('', Validators.required),
                  country: new FormControl('', Validators.required),
                  // shipment: new FormControl('', Validators.required),
                  deposit: new FormControl(false, Validators.required),
                  paypal: new FormControl(false, Validators.required),
                  pay: new FormControl('pending', Validators.required)
                });
              }

  ngOnInit() {
    this.onClearAddress();
    
    // this.sumShipment();


    // this.formCheckoutAddressNew = this._formBuilder.group({
    //   'address': new FormControl(),
    //   city: new FormControl(),
    //   state: new FormControl(),
    //   country: new FormControl(),
    // });

  // this.formCheckoutAddress.setValue(this.address);
  }
  ngAfterViewChecked(): void {
    if ( !this.addScript ) {
      this.addPaypalScript().then(() => {
        paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn');
        this.paypalLoad = true;
      })
    }
  }

  addPaypalScript() {
    this.addScript = true;
    return new Promise((resolve, reject) => {
      let scripttagElement = document.createElement('script');
      scripttagElement.src = 'https://www.paypalobjects.com/api/checkout.js';
      scripttagElement.onload = resolve;
      document.body.appendChild(scripttagElement);
    })
  }
  onClearAddress() {
    this.formCheckout.controls['adrAct'].valueChanges
      .subscribe(data => {
        if (data === false) {
          this.newAddress();
        } else {
          this.useAddress();
        }
      });
    }
  //   sumShipment() {
  //     this.totalPrice = this.totalPrice + this.formCheckout.controls['shipment'].value;
  //     this.formCheckout.controls['shipment'].valueChanges
  //     .subscribe(data => {
  //       const newData = data;
  //       if (newData !== this.oldData) {
  //         this.totalPrice -= this.oldData;
  //         this.oldData = newData;
  //         this.totalPrice += newData;
  //       }

  //     });
  // }
  cartData(uid: string) {
    this.cartService.myCart(uid).subscribe(cart => {
      this.cart = cart.payload.data();
      this.totalPrice = this.cartService.totalPrice(this.cart.products);
      this.finalAmount = this.totalPrice;
    });
  }
  openDialog(product) {
    if (!product.selected) {

      const dialogRef = this.dialog.open(ShipmentDialogComponent, {
        width: '700px',
        data: { product: product, selected: this.selected }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selected = result.selected;
          result.product.selected = this.selected.nameship;
          this.selected.id = result.product.id;
          this.totalPrice = this.totalPrice + +this.selected.pay;
        }
      });

    }
  }
  productData(uid: string) {
    this.chekoutService.cartData(uid).valueChanges().subscribe(data => {
      data['products'].selected = 0;
      this.dataSource = new MatTableDataSource(data['products']);
      this.dataSource.sort = this.csort;
    });
  }
  loadCountry() {
    this._addresService.getCountries().valueChanges().subscribe(countries => {
      this.countries = countries;
      if (countries) {
        for (const county of countries) {
          const countryData = county;
          this._addresService.getStates(countryData).valueChanges().subscribe(states => {
            this.states = states;
          });
        }
      }
    });
  }
  saveOrder() {
    const info = this.formCheckout.value;
    if (info['deposit'] !== false ) {
      this.processOrder(info);
    }

  }
  newAddress() {
    this.formCheckout.patchValue({
      address: '',
      city: '',
      country: '',
      state: ''
    });
  }
  useAddress() {
    this.formCheckout.patchValue({
      address: this.address.address,
      city: this.address.city,
      country: this.address.country,
      state: this.address.state
    });
  }

  processOrder(info: Send) {
    const order: Order = {
      id: null,
      customerId: this.uid,
      products: this.dataSource.data,
      amount: this.totalPrice,
      totalProducts: this.cart.totalProducts,
      created_at: moment(new Date).format('DD/MM/YYYY'),
      num: this.numData,
      paypal: info.paypal,
      deposit: info.deposit,
      pay: info.pay

    };
    // this.sendMail();
    // if(info.payment === 'paypal') {

    // }

    this.orderService.save(order).then(() => {
      this.cartService.resetCart(this.uid).then(() => {
        this.snack.launch('Pedido completado, te enviamos un Email con toda la información', 'Carrito', 10000);
        this.router.navigate(['/explore']);
      }).then(() => {

      });
    });

    if (order.products) {
      for (const product of order.products) {
        const productLocal = {
          info: {},
          product: {},
          adminId: '',
          id: null,
          num: this.numData,
          date: order.created_at
        };
        productLocal.info = info;
        productLocal.product = product;
        productLocal.adminId = product.uid;
        productLocal.id = faker.random.alphaNumeric(16);
        this.orderService.stockUpdate(productLocal);
        this.orderService.adminOrder(productLocal);
      }
    }


  }

  sendMail() {
    this._sendMail.send('jajeantp@gmail.com', 'mensaje de prueba');
  }

  getNum() {
    const ref = this.orderService.setNumOrder();
    ref.get().then( data => {
      const newData = data.data();
      this.numData = newData.value;

    });
  }

  // tslint:disable-next-line:member-ordering
  private static dialogConfig(data): MatDialogConfig {
    const selectedShipment = null;
    const config: MatDialogConfig = new MatDialogConfig;
    config.width = '700px';
    config.data = {product: data, shipment: selectedShipment};
    return config;
  }

}



import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [`
  .example-container {
    position: fixed;
    top: 60px;
    bottom: 60px;
    left: 0;
    right: 0;
    background: none;
  }
  .example-sidenav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    background: rgba(255, 0, 0, 0.5);
  }
  .example-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
  .example-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  `]
})
export class SidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

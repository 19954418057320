import { AscrollService } from '../../common/ascroll.service';
import { CartService } from '../../common/cart.service';
import { AuthService } from '../../auth/auth.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Observable, observable } from 'rxjs';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/combineLatest';

import { ProductsService } from '@common/products.service';
import { Product } from '../../models/product';
import { AppService } from '../../common/app.service';
import { SnackService } from '@common/snack.service';
import { BrandService } from '@common/brand.service';
import { Brand } from '../../models/brand';
import { EventEmitter } from 'events';
import { MatSelectChange, MatListOption, MatListModule, MatSelectionList, MatSelectionListChange, PageEvent } from '@angular/material';
import { CategoryService } from '@common/category.service';
import { Category } from '../../models/category';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  pageSizeOptions = [5, 10, 25, 100];
  pageEvent: PageEvent;
  selectedOptions: any;

  public products: any;
  filteredProducts: any;

  // filter-able properties
  category: string;
  brandin: string;
  categorin: string;
  brands: Brand[] [];
  categories: Category[] [];

  // active filter rule
  filters = {};
  filter = '';
  property: '';

  // scroll
  producto: Array<any> = [];
  // public producto: any;
  batch: number = 9;
  last: any = new Date;
  empty: boolean = false;
  loading: boolean = false;

  constructor(
    private productService: ProductsService,
    public auth: AuthService,
    public cartService: CartService,
    public appService: AppService,
    public snack: SnackService,
    private brandService: BrandService,
    private categoryService: CategoryService,
    public ascrollService: AscrollService
  ) {
    
  }
  
  ngOnInit() {
    this.loading = true;
    this.appService.fireLoader();
    this.fetchProductsPaginated();
    
    // this.fetchProductsPaginated();
    // this.loadProducts(this.property, this.filter);
    // this.productService.products().valueChanges().subscribe(products => {
      //   this.products = products
      // })
      
      
    this.brandService.brands().valueChanges().subscribe(data => {
      this.brands = data;
    })

    this.categoryService.categories().valueChanges().subscribe(data => {
      this.categories = data
    })

    this.appService.stopLoader();
    // console.log(this.products);
  }

  scrollHandler(e) {
    console.log(e);
  }

  private applyFilters() {
    // console.log(this.products)
    // this.products = _.filter(this.products, _.conforms(this.filters))
  }
  fetchProductsPaginated() {
    this.ascrollService.paginate(this.batch, this.last).pipe(
      map(data => {
        console.log(data);
        let last = _.last(data);
        if (last) {
          // this.loading = false;
          this.last = last.created_at;
          data.map(producto => {
            this.producto.push(producto);
          })
        }
        if (data.length < 9) {
          // this.loading = false;
          this.empty = true;

        }
      })
      ).subscribe();
    }
  onScroll () {
    this.loading = true;
    this.fetchProductsPaginated();
    setInterval(() => {
      this.loading = false;
      }, 1500);
      
}

  loadProducts(property: string, filter: string) {
      this.products = this.productService.products(property, filter).snapshotChanges().map(productSnapshot => {
        return productSnapshot.map(product => {
          const productData = product.payload.doc.data();
          const productId = product.payload.doc.id;
          return this.productService.getProductImages(productId).snapshotChanges().map(uploadSnap => {
            let number = 0;
            return uploadSnap.map(upload => {
              if (number === 0) {
                number++;
                return upload.payload.doc.data();
              }
            })
          })
          .map(uploads => {
            return {productId, ...productData, uploads: uploads};
          })
        })
      })
      .flatMap(products => Observable.combineLatest(products))
    }


  addProduct(product: Product) {
    this.cartService.addProduct(product)
      .then(() => {
        this.snack.launch('Producto Agregado', 'Productos', 3000);
      })
      .catch((error) => {
        this.snack.launch('Error: ' + error.message, 'Productos', 3000);
      });
  }

  filterExact(property: string, event: any) {
    if (event.option.selected) {
      event.source.selectedOptions._multiple = false
      this.loadProducts(property, event.option.value)
    } else {
      this.loadProducts(this.property, this.filter)
      this.categorin = null;
      this.brandin = null;
    }
  }

  removeFilter(property: string) {
    delete this.filters[property]
    this[property] = null
    this.applyFilters()
    this.brandin = '';
  }

}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTolower]'
})
export class TolowerDirective {

  constructor(public ref: ElementRef) { }

  @HostListener('input', ['$event']) 
  oninput(event) {
    this.ref.nativeElement.value = event.target.value.toLowerCase();
  }

}

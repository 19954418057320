import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Upload } from '../models/upload';
import { AngularFirestore } from 'angularfire2/firestore';
import * as faker from 'faker';

@Injectable()
export class UploadService {

  // Folder to save files
  private basePath = '/products';
  private avatarPath = '/avatars';

  constructor(private afs: AngularFirestore) { }


  pushFileToStorage(fileUpload: Upload, progress: {percentage: number}, id: any) {
    const storageRef = firebase.storage().ref();
    const fileId = faker.random.alphaNumeric(16);
    const uploadTask = storageRef.child(`${this.basePath}/${fileId}`).put(fileUpload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      // subiendo
      const snap = snapshot as firebase.storage.UploadTaskSnapshot;
      progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
    },
    (error) => {
      // ToDo anejar error
    },
    () => {
      // subida 200
      fileUpload.id = fileId;
      fileUpload.url = uploadTask.snapshot.downloadURL;
      fileUpload.name = fileUpload.file.name;
      this.saveFileData(fileUpload, id);
    }
  );
  }
  pushAvatarToStorage(fileUpload: Upload, progress: {percentage: number}, id: any) {
    const storageRef = firebase.storage().ref();
    const fileId = faker.random.alphaNumeric(16);
    const uploadTask = storageRef.child(`${this.avatarPath}/${fileId}`).put(fileUpload.file);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      // subiendo
      const snap = snapshot as firebase.storage.UploadTaskSnapshot;
      progress.percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
    },
    (error) => {
      // ToDo anejar error
    },
    () => {
      // subida 200
      fileUpload.id = fileId;
      fileUpload.url = uploadTask.snapshot.downloadURL;
      fileUpload.name = fileUpload.file.name;
      this.saveAvatar(fileUpload, id);
    }
  );
  }
  private saveAvatar(fileUpload: Upload, id) {
    const user = this.afs.collection('users').doc(id);
    user.update({
      photoUrl: fileUpload.url
    });

  }

  // Save file
  private saveFileData(fileUpload: Upload, id) {
    const product = this.afs.collection('products').doc(id);
    const newRef = product.collection('uploads').doc(fileUpload.id);
    newRef.set({
      id: fileUpload.id,
      name: fileUpload.name,
      url: fileUpload.url
    });

  }

  // Delete file
  public removeFile(fileId) {
    return firebase.storage().ref().child(`${this.basePath}/${fileId}`).delete();
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from 'angularfire2/firestore';
import { Subcategory } from '../models/subcategory';
import * as faker from 'faker';
import { CategoryService } from '@common/category.service';
import { Category } from '../models/category';

type subCategoryCollection = AngularFirestoreCollection<Subcategory[]>;
type subCategoryDocument = AngularFirestoreDocument<Subcategory>;

@Injectable()
export class SubcategoryService {

  constructor( public afs: AngularFirestore
            ) { }


  // Get collection
  subCategories(): subCategoryCollection {
    return this.afs.collection<Subcategory[]>(`subcategories`);
  }

  // get document
  subCategory(id) {
    return this.afs.collection<Subcategory>(`subcategories`).doc(id);
  }

  saveSubcategory(subcategory: Subcategory): Promise<any> {
    subcategory.id = faker.random.alphaNumeric(16);
    return this.afs.collection<Subcategory[]>(`subcategories`).doc(subcategory.id)
    .set(Object.assign({}, subcategory)).then(() => {
      // this.afs.collection(`categories`).doc(subcategory.categoryId).set(Object.assign({}, subcategory));
    });
  }

  getCategorySubcategories(categoryId: string) {
    return this.afs.collection('subcategories', ref => ref.where('categoryId', '==', categoryId));
    // this.afs.doc<Subcategory>(`subcategories`).where
    // collection('subcategories');
  }

  removeSubcategory(subcategory): Promise<any> {
    // this.afs.collection(`categories`).doc(subcategory.categoryId)
    // const subcatOnCat = this.afs.collection(`categories`, ref => ref.where(`subcategories.` + subcategory.name, '==', `true`));
    return new Promise((resolve, reject) => {
      // remove from Subcategories collection
      this.afs.collection(`subcategories`).doc(subcategory.id).delete();
      // remove from Categories.subategories array
      const subcatOnCat = this.afs.collection<Category>(`categories`).doc(subcategory.categoryId).ref;
      subcatOnCat.get().then(doc => {
        const arrayData = doc.data();
        const subcatOnData = arrayData.subcategories;
        const exist = SubcategoryService.findSubcatArray(subcatOnData, subcategory.name, true);
        if (exist) {
          const index = subcatOnData.indexOf(exist);
          arrayData.subcategories.splice(index, 1);
          return subcatOnCat.update(arrayData)
          .then(() => {
            resolve(true);
          }).catch((err) => {
            reject(err);
          });
        }
      });
    });
  }
  removeParentSubcategory(categoryId): Promise<any> {
    const subcatRef = this.afs.collection(`subcategories`, ref => ref.where(`categoryId`, '==', categoryId));
    return subcatRef.ref.doc().delete();
  }

  categoryUpdateSub(category) {
    return this.afs.collection(`categories`).doc(category.id).update(Object.assign({}, category));
  }

  // tslint:disable-next-line:member-ordering
  static findSubcatArray(array, key, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }

}

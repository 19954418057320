import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '@auth/auth.service';
import { SnackService } from '@common/snack.service';
import { OrdersService } from '../../common/orders.service';

@Component({
  selector: 'app-orderpay-dialog',
  templateUrl: './orderpay-dialog.component.html',
  styleUrls: ['./orderpay-dialog.component.css']
})
export class OrderpayDialogComponent implements OnInit {
  approved = false;

  constructor(
    private dialogRef: MatDialogRef<OrderpayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private auth: AuthService,
    private snack: SnackService,
    public orderService: OrdersService
  ) {
    if (data.info.pay === 'approved') {
      this.approved = true;      
    }
  }

  ngOnInit() {
  }

  updatePay() {
    this.orderService.updatePay(this.data).then(() => {
      this.snack.launch('Pago Actualizado', 'Orden', 4000);
    }).then(() => {
      this.dialogRef.close();
    });
  }

}

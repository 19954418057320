import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@common/user.service';
import { AuthService } from '@auth/auth.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SnackService } from '../../common/snack.service';
import { User } from '../../models/user';
import { AppService } from '../../common/app.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: any;

  displayedColumns = ['displayName', 'email', 'role', 'update', 'delete'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public usersService: UserService,
    public auth: AuthService,
    private snack: SnackService,
    private appService: AppService
  ) {
    this.usersService.users().valueChanges().subscribe((data) => {

      // this.users = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    },
    err => {
      this.snack.launch('Error obteniendo usuarios: ' + err.message, 'Usuarios', 5000);
  });
  }

  ngOnInit() {
  }

  applyFilter(filterValue) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // remove(user: User) {
  //   this.usersService.remove(user.uid);
  // }

  remove(user: User) {
    this.usersService.remove(user.uid).then(() => {
      this.snack.launch('Producto elminado correctamente', 'Productos', 5000);
    });
  }

  update(user: User) {
    this.appService.fireLoader();
    this.usersService.updateUser(user).then(() => {
      this.snack.launch('Producto actualizado', 'Carrito', 4000);
      this.appService.stopLoader();
    })
    .catch(() => {
      this.snack.launch('Error actualizando el producto', 'Carrito', 4000);
      this.appService.stopLoader();
    });
  }



}

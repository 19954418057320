import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { SnackService } from '../../../common/snack.service';
import { UserService } from '@common/user.service';
import { User } from '../../../models/user';
import { Upload } from '../../../models/upload';
import { UploadService } from '@admin/upload.service';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css']
})
export class DataComponent implements OnInit {
  selectedFiles: FileList;
  currentFileUploaded: Upload;
  progress: {percentage: number} = {percentage: 0};

  public data: User = {
    displayName: '',
    email: '',
    photoUrl: '',
    role: '',
    uid: '',
    phone: '',
    address: false
  };

  constructor(
    public auth: AuthService,
    private snack: SnackService,
    public _userService: UserService,
    private uploadService: UploadService

  ) {
      this.auth.user.subscribe(user => {
        this.data = user;
      },
      err => {
        this.snack.launch('Error obteniendo usuario: ' + err.message, 'Perfil', 5000);
    });
  }

  ngOnInit() {
  }

  updateProfile() {
    this._userService.updateUser(this.data).then(() => {
      this.snack.launch('Usuario actualizado', 'Perfil', 4000);
    })
    .catch(error => {
      this.snack.launch('Error: ' + error.message, 'Perfil', 4000);
    });

  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
  }

  upload() {
    const file = this.selectedFiles.item(0);
    this.currentFileUploaded = new Upload(file);
    this.uploadService.pushAvatarToStorage(this.currentFileUploaded, this.progress, this.data.uid);
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from 'angularfire2/firestore'
import { Observable } from 'rxjs/Observable';
import { Product } from '../models/product';
import { ProductsService } from '@common/products.service';

@Injectable({
  providedIn: 'root'
})
export class AscrollService {

  constructor(
    private afs: AngularFirestore,
    private productService: ProductsService
  ) { }

  paginate(limit: number, last: any): Observable<any[]> {
    return this.afs.collection('products', (ref) => (
      ref
        .where('created_at', '<', last)
        .orderBy('created_at', 'desc')
        .limit(limit)
    ) ).snapshotChanges().map(productSnapshot => {
      return productSnapshot.map(product => {
        const productData = product.payload.doc.data();
        const productId = product.payload.doc.id;
        return this.productService.getProductImages(productId).snapshotChanges().map(uploadSnap => {
          let number = 0;
          return uploadSnap.map(upload => {
            if (number === 0) {
              number++;
              return upload.payload.doc.data();
            }
          })
        })
        .map(uploads => {
          return {productId, ...productData, uploads: uploads};
        })
      })
    })
    .flatMap(products => Observable.combineLatest(products))
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { SharedModule } from '../shared/shared.module';

// Services
import { AuthService } from './auth.service';

// Components
import { FormComponent } from './form/form.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';

// Guards
import { AuthGuard } from './auth.guard';
import { CustomerGuard } from './customer.guard';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    AngularFireAuthModule,
    SharedModule
  ],
  exports: [
    FormComponent,
    RegisterComponent,
    LoginComponent
  ],
  declarations: [FormComponent, RegisterComponent, LoginComponent],
  providers: [AuthService, AuthGuard, CustomerGuard]
})
export class AuthModule { }

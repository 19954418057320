import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Categorynode, SubcategoryNode } from '../models/categorynode';
import { CategoryService } from '@common/category.service';

@Injectable({
  providedIn: 'root'
})
export class MenuDataService {
  dataChange: BehaviorSubject<Categorynode[]> = new BehaviorSubject<Categorynode[]>([]);

  get data(): Categorynode[] { return this.dataChange.value; }

  constructor( private categoryService: CategoryService) {

    this.categoryService.categories().valueChanges().subscribe(categories => {
      this.initialize(categories);
      
    })
  }

  initialize(categories) {
    const dataObject = categories;
    
    const data = this.buildFileTree(dataObject, 0);
    
    this.dataChange.next(data);
  }
  
  buildFileTree(value: any, level: number): Categorynode[] {
    let data: any[] = [];
    
    for (let k in value) {
      // let subcat: ;
      let v = value[k].name;
      let node = new Categorynode();
      node.categoryName = `${v}`;
      if (value[k].subcategories) {
        for (let b of value[k].subcategories) {
          
          let key = Object.keys(b).toString()
          
          // key.toString();
          node.subcategory = new SubcategoryNode();
          node.subcategory.name = key ;
        }
        
      }
      // if (v === null || v === undefined) {
        
        // } else if (typeof value.subcategories === 'object') {
          //   for (let k in value.subcategories) {
            //     let b = value.subcategories[k]
            //     node.subcategory = this.buildFileTree(k, level + 1);
            //   }
            //   node.subcategory = this.buildFileTree(v, level + 1);
            // } 
            // node.subcategory = subcat;
            data.push(node);
          }
    return data;

  }
  
}

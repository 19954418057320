import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { Brand } from '../../../models/brand';
import { SnackService } from '@common/snack.service';
import { AuthService } from '@auth/auth.service';
import { ModelService } from '../../../common/model.service';
import { Model } from '../../../models/model';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styles: [`
  .sub-chip-list {
    width: 100%
  }
  `]
})
export class ModelsComponent implements OnInit {
  model: Model = {
    id: '',
    name: '',
    brandId: ''
  };
  models;
  id: string;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  constructor(
    public dialogRef: MatDialogRef<ModelsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Brand,
    private snack: SnackService,
    private auth: AuthService,
    public modelService: ModelService
  ) {
    if (data.id) {
      this.model.brandId = data.id;
      this.models = this.modelService.getBrandModels(data.id).snapshotChanges().map(actions => {
        return actions.map(model => {
          // tslint:disable-next-line:no-shadowed-variable
          const data = model.payload.doc.data();
          const id = model.payload.doc.id;
          return {id, ...data};
        });
      });


    }
  }

  ngOnInit() {
  }

  saveModel() {
    const map: {[key: string]: boolean} = {};
    map[this.model.name] = true;
    // this.data.subcategories = map; viejo comment
    if (!this.data.models) {
      this.data.models = new Array(map);
    } else {
      this.data.models.push(map);
    }
    this.modelService.saveModel(this.model).then(() => {
      this.snack.launch('Modelo creado', 'Marcas', 4000);
      // this.categoryService.category(this.data.id).push().set({
      //   map
      // })
      this.modelService.brandUpdateModel(this.data);
    })
    .catch(error => {
      this.snack.launch('Error: ' + error.message, 'Marcas', 4000);
    });

    this.dialogRef.close();
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our subcat
    if ((value || '').trim()) {
      this.models.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  // delete subcategory
  remove(model: Model) {

    this.modelService.removeModel(model).then(() => {
      this.snack.launch('Modelo Eliminado', 'Marcas', 4000);
    }).catch(error => {
      this.snack.launch('Error: ' + error.message, 'Marcas', 4000);
    });
    this.dialogRef.close();
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatDialog, MatDialogConfig, MatPaginator } from '@angular/material';
import { CategoryService } from '@common/category.service';
import { AuthService } from '../../auth/auth.service';
import { SnackService } from '@common/snack.service';
import { Category } from '../../models/category';
import { CategoryDialogComponent } from '@admin/categories/category-dialog.component';
import { Subcategory } from '../../models/subcategory';
import { SubcategoriesComponent } from '../subcategories/subcategories.component';
import { SubcategoryService } from '../../common/subcategory.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  subcats;
  datas;

  displayedColumns = ['name', 'description', 'actions', 'add'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) csort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private categoryService: CategoryService,
    public auth: AuthService,
    private snack: SnackService,
    public dialog: MatDialog,
    private subcategoryService: SubcategoryService
  ) {
    this.categoryService.categories().valueChanges().subscribe(data => {
      this.datas = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.csort;

    },
      err => {
        this.snack.launch('Error obteniendo categorías: ' + err.message, 'Categorías', 5000);
    });
  }

  ngOnInit() {
  }

  addCategory() {
    const category: Category = new Category;
    this.dialog.open(CategoryDialogComponent, CategoriesComponent.dialogConfig(category));
  }

  addSubCategory(category: Category) {
    // const category: Subcategory = new Subcategory;
    // subcategory.categoryId = id;
    this.dialog.open(SubcategoriesComponent, CategoriesComponent.dialogConfig(category));

  }

  openDialog(category: Category) {
    this.dialog.open(CategoryDialogComponent, CategoriesComponent.dialogConfig(category));
  }

  // tslint:disable-next-line:member-ordering
  private static dialogConfig(data): MatDialogConfig {
    const config: MatDialogConfig = new MatDialogConfig;
    config.width = '700px';
    config.data = data;
    return config;
  }

  getSubcats(categoryId: string) {
      this.subcats = this.subcategoryService.getCategorySubcategories(categoryId);
  }

  applyFilter(filterValue) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  trackById(index, category: Category) {
      return category.id;
  }

  removeCat(categoryId) {
    this.categoryService.removeCategory(categoryId).then(() => {
      this.snack.launch('Categoría elminada correctamente', 'Categorías', 5000);
    }).catch(err => {
      this.snack.launch('Error: ' + err.message, 'Productos', 5000);

    });
  }

}

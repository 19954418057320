import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { ShopModule } from '@shop/shop.module';
import { QuillModule } from 'ngx-quill';
import { NgxEditorModule } from 'ngx-editor';
import { HttpClientModule } from '@angular/common/http';

// Routes
import { AdminRoutingModule } from './admin-routing.module';

// Services
import { UploadService } from './upload.service';
import { UserService } from '../common/user.service';
import { CategoryService } from '../common/category.service';
import { SubcategoryService } from '../common/subcategory.service';
import { BrandService } from '../common/brand.service';
import { ModelService } from '../common/model.service';
import { AddressService } from '../common/address.service';

// pipes

// Components
import { AdminComponent } from './admin/admin.component';
import { ProductsDialogComponent } from './products-dialog/products-dialog.component';
import { ProductsComponent } from './products/products.component';
import { UploadFormComponent } from './upload-form/upload-form.component';
import { UsersComponent } from './users/users.component';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryDialogComponent } from './categories/category-dialog.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { ExpansionSubcatsComponent } from './expansion-subcats/expansion-subcats.component';
import { BrandsComponent } from './brands/brands.component';
import { BrandComponent } from './brands/brand.component';
import { ModelsComponent } from './brands/models/models.component';
import { ProfileComponent } from './profile/profile.component';
import { DataComponent } from './profile/data/data.component';
import { AddressComponent } from './profile/address/address.component';
import { OrdersadmComponent } from './ordersadm/ordersadm.component';
import { CartComponent } from '@shop/cart/cart.component';
import { OrdersadmDialogComponent } from './ordersadm/ordersadm-dialog.component';
import { OrderpayDialogComponent } from './ordersadm/orderpay-dialog.component';
import { TolowerDirective } from '../directives/tolower.directive';

@NgModule({
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    ShopModule,
    QuillModule,
    HttpClientModule,
    NgxEditorModule
  ],
  declarations: [
    AdminComponent,
    ProductsDialogComponent,
    ProductsComponent,
    UploadFormComponent,
    UsersComponent,
    CategoriesComponent,
    CategoryDialogComponent,
    SubcategoriesComponent,
    ExpansionSubcatsComponent,
    BrandsComponent,
    BrandComponent,
    ModelsComponent,
    ProfileComponent,
    DataComponent,
    AddressComponent,
    OrdersadmComponent,
    OrdersadmDialogComponent,
    OrderpayDialogComponent,
    TolowerDirective,
  ],
  entryComponents: [
    ProductsDialogComponent,
    CategoryDialogComponent,
    SubcategoriesComponent,
    BrandComponent,
    ModelsComponent,
    OrdersadmDialogComponent,
    OrderpayDialogComponent
  ],
  providers: [
    UploadService,
    UserService,
    CategoryService,
    SubcategoryService,
    BrandService,
    ModelService,
    AddressService
  ]
})
export class AdminModule { }

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import * as faker from 'faker';
import { Order } from '../models/order';

type ordersCollection = AngularFirestoreCollection<Order[]>;
type ordersDocumnet = AngularFirestoreDocument<Order>;

@Injectable()
export class OrdersService {

  constructor(
    private afs: AngularFirestore
  ) { }

  ordering() {
    return this.afs.collection(`orders`);
  }

  orderUnit(uid) {
    return this.afs.collection(`adminorders`, ref => ref.where(`adminId`, '==', uid));
  }

  orderGet(num: number) {
    return this.afs.collection<Order>(`orders`, ref => ref.where(`num`, '==', num));
  }

  orders(uid?: string) {
    if (uid) {
      return this.afs.collection<Order[]>('orders', ref => ref.where('customerId', '==', uid)
      // .orderBy('created_at', 'desc')
    );
    }
    return this.afs.collection<Order[]>('orders');
  }

  order(id: string): ordersDocumnet {
    return this.afs.doc<Order>(`orders/${id}`);
  }

  save(order: Order): Promise<any> {
    const id = faker.random.alphaNumeric(16);
    // const id: any = Date.now();
    order.id = id;
    return this.ordering().doc(id).set(Object.assign({}, order)).then(() => {
      this.updateNum(order.num);
      // if (order.products) {
      //   for (const productDeta of order.products) {

      //   }
      // }
    });
  }
  saveReportpay(report): Promise<any> {
    report.id = faker.random.alphaNumeric(16);
    return this.afs.collection(`reportpay`).doc(report.id).set(Object.assign({}, report))
  }
  updateReportpay(report): Promise<any> {
    return this.afs.collection(`reportpay`).doc(report.id).update(Object.assign({}, report));
  }
  updateStatusCustomer(data) {
    return this.afs.collection(`orders`).doc(data.id).update(Object.assign({}, data));
  }

  updateStatus(data) {
    return this.afs.collection(`adminorders`).doc(data.id).update(Object.assign({}, data));
          // for (let product of doc['products']) {
  }
  updatePay(data) {
    return this.afs.collection(`adminorders`).doc(data.id).update(Object.assign({}, data)).then(() => {
      this.afs.collection<Order>(`orders`).ref.where('num', '==', data.num).get().then(order => {
        for (let doc of order.docs){
          this.afs.collection(`orders`).doc(doc.id).update({
            pay: data.info.pay
          })
        }
      })
    })

  }

  productRef(uid: string) {
    return this.afs.collection(`products`).doc(uid).ref;
  }

  stockUpdate(product) {
    // const ref = this.productRef(product.id);
    // ref.get().then(doc => {
    //   const productData = doc.data();
    //   const canty = productData.stock;
    //   // tslint:disable-next-line:radix
    //   const newQty = parseInt(canty) - parseInt(product.qty);
    //   this.afs.collection(`products`).doc(product.id).update({
    //     stock: newQty
    //   });
    // });

  }
  adminOrder(product) {
    this.afs.collection(`adminorders`).doc(product.id).set(Object.assign({}, product));
  }

  setNumOrder() {
    return this.afs.collection(`numorder`).doc('num1').ref;

  }

  updateNum(doc) {
      let sum;
      // tslint:disable-next-line:radix
      sum = parseInt(doc) + 1;
      this.afs.collection(`numorder`).doc('num1').update({
        value: sum
      });

  }

  getReport(num) {
    return this.afs.collection(`reportpay`, ref => ref.where('num', '==', num));
  }

}

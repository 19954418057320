import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatDialogModule,
  MatOptionModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatTreeModule,
  MatPaginatorModule,
  MatBadgeModule,
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { OwlModule } from 'ngx-owl-carousel';
import { SlideshowModule } from 'ng-simple-slideshow';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NguCarouselModule } from '@ngu/carousel';

// Services
import { AppService } from '../common/app.service';
import { SnackService } from '../common/snack.service';
import { ProductsService } from '../common/products.service';
import { CartService } from '../common/cart.service';
import { OrdersService } from '../common/orders.service';

// Pipes
import { SubcatPipe } from '../pipes/subcat.pipe';
import { KeysPipe } from '../pipes/keys.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { CapitalizePipe } from '../pipes/capitalize.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SubcatPipe,
    KeysPipe,
    SafeHtmlPipe,
    CapitalizePipe
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatDialogModule,
    MatOptionModule,
    MatProgressBarModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatStepperModule,
    MatSidenavModule,
    MatExpansionModule,
    MatTooltipModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTreeModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    SubcatPipe,
    KeysPipe,
    MatBadgeModule,
    OwlModule,
    SlideshowModule,
    SafeHtmlPipe,
    CapitalizePipe,
    NguCarouselModule
  ],
  providers: [AppService, SnackService, ProductsService, CartService, OrdersService]
})
export class SharedModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './product/product.component';
import { CartComponent } from '@shop/cart/cart.component';
import { OrdersComponent } from '@shop/orders/orders.component';
import { CustomerGuard } from '../auth/customer.guard';
import { CheckoutComponent } from './checkout/checkout.component';
import { ProductsFilterComponent } from './products-filter/products-filter.component';
import { HelpComponent } from './help/help.component';

const routes: Routes = [
  { path: 'explore', component: ProductsComponent },
  { path: 'filter', component: ProductsFilterComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'product/:id', component: ProductComponent },
  { path: 'product/:model/:name', component: ProductComponent },
  { path: 'nothi', component: HelpComponent },
  { path: 'cart', component: CartComponent, canActivate: [CustomerGuard] },
  { path: 'orders', component: OrdersComponent, canActivate: [CustomerGuard] },
  { path: '**', pathMatch: 'full', redirectTo:'home' },
  { path: '', pathMatch: 'full', redirectTo:'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }

import { Component, OnInit, HostListener, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ProductsService } from '@common/products.service';
import { Observable } from 'rxjs';
import { Brand } from '../../models/brand';
import { Category } from '../../models/category';
import { BrandService } from '@common/brand.service';
import { CategoryService } from '@common/category.service';
import { Product } from '../../models/product';
import { CartService } from '../../common/cart.service';
import { SnackService } from '@common/snack.service';
import { AuthService } from '@auth/auth.service';
import { AppService } from '../../common/app.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-products-filter',
  templateUrl: './products-filter.component.html',
  styleUrls: ['./products-filter.component.css']
})
export class ProductsFilterComponent implements OnInit {
  loading = false;

  public products: any;

  // active filter rule
  filters = {};
  filter = '';
  property: '';

  // filter-able properties
  category: string;
  brandin: string;
  categorin: string;
  brands: Brand[] [];
  categories: Category[] [];

  // Responsive sidenav
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  // Responsive with ngIf
  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
  }
  innerWidth: any;
  innerHeight: any;

  constructor(
    public productService: ProductsService,
    private brandService: BrandService,
    private categoryService: CategoryService,
    public cartService: CartService,
    private snack: SnackService,
    public auth: AuthService,
    public appService: AppService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 999px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.appService.fireLoader();
    this.loadProducts(this.property, this.filter);

    this.brandService.brands().valueChanges().subscribe(data => {
      this.brands = data;
    })

    this.categoryService.categories().valueChanges().subscribe(data => {
      this.categories = data
    })
    


    // this.innerHeight = window.innerHeight;
    // this.innerWidth = window.innerWidth;

    this.appService.stopLoader();
    
  }

  loadProducts(property: string, filter: string) {
    this.loading = true;
    this.products = this.productService.products(property, filter).snapshotChanges().map(productSnapshot => {
      return productSnapshot.map(product => {
        const productData = product.payload.doc.data();
        const productId = product.payload.doc.id;
        return this.productService.getProductImages(productId).snapshotChanges().map(uploadSnap => {
          let number = 0;
          return uploadSnap.map(upload => {
            if (number === 0) {
              number++;
              return upload.payload.doc.data();
            }
          })
        })
        .map(uploads => {
          return {productId, ...productData, uploads: uploads};
        })
      })
    })
    .flatMap(products => Observable.combineLatest(products));
    this.loading = false;
  }

  addProduct(product: Product) {
    this.cartService.addProduct(product)
      .then(() => {
        this.snack.launch('Producto Agregado', 'Productos', 3000);
      })
      .catch((error) => {
        this.snack.launch('Error: ' + error.message, 'Productos', 3000);
      });
  }

  filterExact(property: string, event: any) {
    console.log(property, event)
    if (event.option.selected) {
      event.source.selectedOptions._multiple = false
      this.loadProducts(property, event.option.value.toLowerCase())
    } else {
      this.loadProducts(this.property, this.filter)
      this.categorin = null;
      this.brandin = null;
    }
  }

  removeFilter(property: string) {
    delete this.filters[property]
    this[property] = null
    this.applyFilters()
    this.brandin = '';
  }

  private applyFilters() {
    // console.log(this.products)
    // this.products = _.filter(this.products, _.conforms(this.filters))
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

}

import { Component, OnInit, Inject, Input } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatChipInputEvent } from '@angular/material';
import { Subcategory } from '../../models/subcategory';
import { SnackService } from '@common/snack.service';
import { AuthService } from '@auth/auth.service';
import { CategoryService } from '@common/category.service';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import { Category } from '../../models/category';
import { Subcats } from '../../models/subcats';
import { SubcategoryService } from '../../common/subcategory.service';
import { FirebaseApp } from 'angularfire2';
// import { Fireb } from 'firebase';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styles: [`
  .sub-chip-list {
    width: 100%
  }
  `]
})
export class SubcategoriesComponent implements OnInit {

  id: string;
  subcats;
  subcategory: Subcategory = {
    id: '',
    name: '',
    categoryId: ''
  };

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;


  constructor(
    public afs: AngularFirestore,
    public dialogRef: MatDialogRef<SubcategoriesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Category,
    private snack: SnackService,
    public auth: AuthService,
    public categoryService: CategoryService,
    public subcategoryService: SubcategoryService
  ) {

    // Get subcategories from especific category
    if (data.id) {
      this.id = data.id;
      this.subcategory.categoryId = this.id;
      this.subcats = this.subcategoryService.getCategorySubcategories(this.id).snapshotChanges().map(actions => {
        return actions.map(upload => {
          // tslint:disable-next-line:no-shadowed-variable
          const data = upload.payload.doc.data();
          const id = upload.payload.doc.id;
          return {id, ...data};
        });
      });


    }


  }

  ngOnInit() {
  }
  // Save subcategory
  saveSubcategory() {
    const map: {[key: string]: boolean} = {};
    map[this.subcategory.name] = true;
    // this.data.subcategories = map; viejo comment
    if (!this.data.subcategories) {
      this.data.subcategories = new Array(map);
    } else {
      this.data.subcategories.push(map);
    }
    this.subcategoryService.saveSubcategory(this.subcategory).then(() => {
      this.snack.launch('Subcategoría creada', 'Categorías', 4000);
      // this.categoryService.category(this.data.id).push().set({
      //   map
      // })
      this.subcategoryService.categoryUpdateSub(this.data);
    })
    .catch(error => {
      this.snack.launch('Error: ' + error.message, 'Categorías', 4000);
    });

    this.dialogRef.close();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our subcat
    if ((value || '').trim()) {
      this.subcats.push({ name: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  // delete subcategory
  remove(subcategory: Subcategory) {

    this.subcategoryService.removeSubcategory(subcategory).then(() => {
      this.snack.launch('Subcategoría Eliminada', 'Categorías', 4000);
    }).catch(error => {
      this.snack.launch('Error: ' + error.message, 'Categorías', 4000);
    });
    this.dialogRef.close();
  }


}

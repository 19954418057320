import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


import { OrdersComponent } from '@shop/orders/orders.component';
import { DataComponent } from './data/data.component';
import { AddressComponent } from './address/address.component';

export const PROFILE_ROUTES: Routes = [
    { path: 'data', component: DataComponent },
    { path: 'address', component: AddressComponent },
    { path: 'orders', component: OrdersComponent }

];

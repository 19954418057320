import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontRoutingModule } from './front-routing.module';
import { SharedModule } from '@shared/shared.module';
import { AuthModule } from '../auth/auth.module';

// Components
import { HomeComponent } from './home/home.component';

@NgModule({
  imports: [
    CommonModule,
    FrontRoutingModule,
    SharedModule,
    AuthModule
  ],
  declarations: [HomeComponent]
})
export class FrontModule { }

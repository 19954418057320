import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subcat'
})
export class SubcatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let keys = [];
    for (let key of value) {
      if (key) {
        for ( let k in key) {
          keys.push(k)

        }
      }
    }
    return keys;
  }

}

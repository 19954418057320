import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { AuthService } from '@auth/auth.service';
import { ProductsService } from '@common/products.service';
import { SnackService } from '@common/snack.service';
import { Product } from '../../models/product';
import { ProductsDialogComponent } from '@admin/products-dialog/products-dialog.component';
import { User } from '../../models/user';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  user: User = {
    displayName: '',
    email: '',
    photoUrl: '',
    role: '',
    uid: '',
    address: false
  };

  displayedColumns = ['name', 'price', 'description', 'actions'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private productService: ProductsService,
    public dialog: MatDialog,
    private snack: SnackService,
    public auth: AuthService
  ) {
    this.auth.user.subscribe(user => {
      if (user) {
        this.productService.productsUid(user.uid).valueChanges().subscribe(data => {
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        err => {
          this.snack.launch('Error obteniendo productos: ' + err.message, 'Productos', 5000);
      });
    }
    });
  }
  ngOnInit() {

  }


  applyFilter(filterValue) {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
  }

  trackById(index, product: Product) {
      return product.id;
  }

  addProduct() {
    const product: Product = new Product;
    this.dialog.open(ProductsDialogComponent, ProductsComponent.dialogConfig(product));
  }

  openDialog(product: Product) {
    this.dialog.open(ProductsDialogComponent, ProductsComponent.dialogConfig(product));
  }

  // tslint:disable-next-line:member-ordering
  private static dialogConfig(data): MatDialogConfig {
    const config: MatDialogConfig = new MatDialogConfig;
    config.width = '70%';
    config.panelClass = 'custom-contain';
    config.data = data;
    return config;
  }

  remove(product: Product) {
    this.productService.remove(product.id).then(() => {
      this.snack.launch('Producto Eliminado', 'Tienda', 4000);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Tienda', 4000);
      });
  }

}

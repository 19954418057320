import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { CartService } from '../cart.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { LoginComponent } from '@auth/login/login.component';
import { RegisterComponent } from '@auth/register/register.component';
import { User } from '../../models/user';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  user: User;

  cart: any;
  @HostListener('window:listener', ['$event'])
  onresize(event) {
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
  }
  innerWidth: any;
  innerHeight: any;

  constructor(public auth: AuthService,
              public cartService: CartService,
              public dialog: MatDialog) {}

  ngOnInit() {
    this.auth.user.subscribe(data => {
      if (data) {
        this.user = data;
        if (data.role === 'customer') {
          const cartRef = this.cartService.myCartRef(data.uid).get();
          cartRef.then((cart) => {
            if (cart.exists) {
              this.cartService.myCart(data.uid).subscribe(myCart => {
                this.cart = myCart.payload.data();
              });
            } else {
              this.cartService.createCart(data.uid);
              this.cartService.myCart(data.uid).subscribe(myCart => {
                this.cart = myCart.payload.data();
              });
            }
          });

        }
      }
    });
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  loginDia() {
    this.dialog.open(LoginComponent, NavigationComponent.dialogConfig())
  }
  registerDia() {
    this.dialog.open(RegisterComponent, NavigationComponent.dialogConfig())
  }

  private static dialogConfig(): MatDialogConfig {
    const config: MatDialogConfig = new MatDialogConfig;
    config.width = '400px';
    config.panelClass = 'custom-container';
    return config;
  }
  
}

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackService } from '@common/snack.service';
import { AuthService } from '@auth/auth.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CartService } from '../../../common/cart.service';

@Component({
  selector: 'app-shipment-dialog',
  templateUrl: './shipment-dialog.component.html',
  styleUrls: ['./shipment-dialog.component.css']
})
export class ShipmentDialogComponent implements OnInit {

  selected = {
    nameship: '',
    pay: '',
    id: ''
  };
  localship = {
    nameship: 'Desde GPS',
    pay: '200'
  };

  product;



  constructor(
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<ShipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snack: SnackService,
    public auth: AuthService,
    private _formBuilder: FormBuilder,
    public cartService: CartService
  ) {
    this.product = data.product;
    this.selected.id = data.id;
  }

  ngOnInit() {

  }

  cargaShipment(selected) {
    this.data.shipment = selected;
    this.dialogRef.close();
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SnackService } from '@common/snack.service';
import { OrdersService } from '../../common/orders.service';
import { AuthService } from '@auth/auth.service';
import { Order } from '../../models/order';

@Component({
  selector: 'app-ordersadm-dialog',
  templateUrl: './ordersadm-dialog.component.html',
  styleUrls: ['./ordersadm-dialog.component.css']
})
export class OrdersadmDialogComponent implements OnInit {
  order: Order
  orderProduct: any;
  id: string;

  constructor(
    private dialogRef: MatDialogRef<OrdersadmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private auth: AuthService,
    private snack: SnackService,
    public orderService: OrdersService
  ) {
    this.id = data.product.id;
    this.orderService.orderGet(data.num).valueChanges().subscribe(order => {
      for (let ord of order) {
        this.order = ord;
      }
    })
    
  }

  ngOnInit() {
  }

  updateOrder() {
    this.orderService.updateStatus(this.data).then(() => {
      this.snack.launch('Envío Actualizado', 'Orden', 4000);
    }).then(() => {
      this.createOrder(this.data);
      this.dialogRef.close();
    });
  }
  createOrder(data: any) {
    const exists = OrdersadmDialogComponent.findProductByKey(this.order.products, 'id', data.product.id);
        if (exists) {
          const index = this.order.products.findIndex(obj => obj.id === data.product.id);
          this.order.products[index]['status'] = data.product.status;
          this.orderService.updateStatusCustomer(this.order);
        }
  }

  static findProductByKey(array, key, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }

}

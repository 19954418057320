import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {
  transform(value, arg: string[]): any {
    const keys = [];
    if (value) {
      for ( const key of value) {
        if (key) {
          for (const k of Object.keys(key)) {
              keys.push(k);
            }
          } else {
          }
        }
      } else {
      }
      return keys;
  }

  // transform(value, args: string[]): any {
  //   const keys = [];
  //   for (const key of value) {
  //     if ( key !== null ) {
  //       for (const k of Object.keys(key)) {
  //         if ( k ) {
  //           keys.push({key: k, value: value[k]});
  //         }
  //         return keys;
  //       }
  //     } else {
  //       return keys;
  //     }
  //   }
  // }
}

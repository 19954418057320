import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { User } from '../models/user';
import { Observable ,  from as fromPromise } from 'rxjs';
import { expand, takeWhile, mergeMap, take } from 'rxjs/operators';
import { AngularFireAuth } from 'angularfire2/auth';

type productCollection = AngularFirestoreCollection<User[]>;
type productDocument = AngularFirestoreDocument<User>;


@Injectable()
export class UserService {
  userAu: Observable<User>;

  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) { }

  users() {
    return this.afs.collection<User[]>('users');
  }

  remove(id) {
    const ref = this.user(id);
    return new Promise((resolve, reject) => {
      ref.delete().then(() => {
        resolve(true);
      }).catch(error => {
        reject(error);
      });
    });
  }

  user(id: string): productDocument {
    return this.afs.doc<User>(`users/${id}`);
  }

  updateUser(user: User): Promise<any> {
    return this.user(user.uid).update(Object.assign({}, user));
  }

  userAuth(id: string) {
    this.afAuth.authState.switchMap(user => {
      if (user) {
        return this.afs.doc<User>(`users/${id}`).valueChanges();
      } else {
        return Observable.of(null);
      }
    });

  }

  updateAddress(id: string) {
    const user = this.afs.collection(`users`).doc(id);
    user.update({
      address: true
    });
  }

}

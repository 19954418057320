import { Component, OnInit } from '@angular/core';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource, MatTreeNestedDataSource } from '@angular/material';
import { CategoryService } from '@common/category.service';
import { Category } from '../../models/category';
import { BehaviorSubject, of as observableOf } from 'rxjs';
import { MenuDataService } from '../../common/menu-data.service';
import { Categorynode } from '../../models/categorynode';

@Component({
  selector: 'app-menu-side',
  templateUrl: './menu-side.component.html',
  styleUrls: ['./menu-side.component.css']
})
export class MenuSideComponent implements OnInit {

  nestedTreeControl: NestedTreeControl<Categorynode>

  // treeFlattener: MatTreeFlattener<any, any>

  dataSource: MatTreeNestedDataSource<Categorynode>

  constructor(public categoryService: CategoryService,
              public menuData: MenuDataService) {
    this.dataSource = new MatTreeNestedDataSource();
    // this.nestedTreeControl = new NestedTreeControl<any>(this._getChildren);

    menuData.dataChange.subscribe(data => {
      this.dataSource.data = data
    })


    // this.categoryService.categories().valueChanges().subscribe(categories => {
    //   this.dataSource.data = categories
    // })
  }

  ngOnInit() {
  }

  private _getChildren = (node: Categorynode) => {
    return observableOf(node.subcategory);
  };

  hasNestedChild = (_: number, nodeData: any) => {
    let subcategory = {
      name: ''
    }
    for (let i in nodeData.subcategory) {
      subcategory.name = nodeData.subcategory[i];
    }
    return subcategory.name;
  };

}

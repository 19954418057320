import { Component, ViewChild } from '@angular/core';
import { BrandService } from '@common/brand.service';
import { AuthService } from '../../auth/auth.service';
import { SnackService } from '@common/snack.service';
import { MatDialog, MatTableDataSource, MatSort, MatDialogConfig, MatPaginator } from '@angular/material';
import { Brand } from '../../models/brand';
import { BrandComponent } from './brand.component';
import { ModelsComponent } from './models/models.component';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.css']
})
export class BrandsComponent {
  datas;

  displayedColumns = ['name', 'description', 'actions', 'add'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) csort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private brandService: BrandService,
    public auth: AuthService,
    private snack: SnackService,
    public dialog: MatDialog,
  ) {
    this.brandService.brands().valueChanges().subscribe(data => {
      this.datas = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.csort;
    },
    err => {
      this.snack.launch('Error obteniendo marcas: ' + err.message, 'Marcas', 5000);
    });
  }

  addBrand() {
    const brand: Brand = new Brand;
    this.dialog.open(BrandComponent, BrandsComponent.dialogConfig(brand));
  }

  addModel(brand: Brand) {
    this.dialog.open(ModelsComponent, BrandsComponent.dialogConfig(brand));

  }

  openDialog(brand: Brand) {
    this.dialog.open(BrandComponent, BrandsComponent.dialogConfig(brand));
  }

  // tslint:disable-next-line:member-ordering
  private static dialogConfig(data): MatDialogConfig {
    const config: MatDialogConfig = new MatDialogConfig;
    config.width = '700px';
    config.data = data;
    return config;
  }

}

import { Component, OnInit } from '@angular/core';
import { Address } from '../../../models/address';
import { AuthService } from '../../../auth/auth.service';
import { AddressService } from '../../../common/address.service';
import { SnackService } from '@common/snack.service';
import { User } from 'firebase/app';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm, FormArray } from '@angular/forms';
import { UserService } from '@common/user.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit {
  address: any;
  // Address = {
  //   id: '',
  //   address: '',
  //   city: '',
  //   state: '',
  //   country: '',
  //   uid: '',
  // };
  addressExist: Address = {
    id: '',
    address: '',
    city: '',
    state: '',
    country: '',
    uid: ''
  };
  data: any;
  uid: string;

  countries: any;
  states: any;

  addressForm: FormGroup;

  constructor(
    public auth: AuthService,
    private _addresService: AddressService,
    private snack: SnackService,
    private _formBuilder: FormBuilder,
    private _userService: UserService
  ) {
      this._addresService.getCountries().valueChanges().subscribe(countries => {
        this.countries = countries;
        if (countries) {
          for (const county of countries) {
            const countryData = county;
            this._addresService.getStates(countryData).valueChanges().subscribe(states => {
              this.states = states;
            });
          }
        }
      });
      this.auth.user.subscribe(user => {
        this.uid = user.uid;
        if (user.address !== false) {
          this._addresService.getAddress(user.uid).valueChanges().subscribe(address => {
            if (address) {
              for ( const addr of address) {
                this.addressExist = addr;
              }
            }
          });

            // const addressExist = {
            //  id: perol.id,
            //  address: perol.address,
            //  city: perol.city,
            //  state: perol.state,
            //  country: perol.country,
            //  uid: perol.uid

          // .valueChanges().subscribe(data => {
          //   this.address = data;
          // });
        } else {
          return this.address = {};
        }
      },
      err => {
        this.snack.launch('Error obteniendo usuario: ' + err.message, 'Perfil', 5000);
      });

      this.addressForm = this._formBuilder.group({
          address: new FormControl(),
          city: new FormControl(),
          state: new FormControl(),
          country: new FormControl(),
      });
      this.onChangesCountry();
  }

  ngOnInit() {
  }

  saveAddress() {
    // address.uid = this.uid;
    // if (this.address.id) {
    //   this._addresService.updateAddress(this.address).then(() => {
    //     this.snack.launch('Dirección actualizada', 'Perfil', 4000);
    //   })
    //   .catch(error => {
    //     this.snack.launch('Error: ' + error.message, 'Perfil', 4000);
    //   });
    // } else {
      const addressNew = this.addressForm.value;
      addressNew.uid = this.uid;
      this._addresService.addAddress(addressNew).then(() => {
        this.snack.launch('Dirección creada', 'Perfil', 4000);
        this.updateUser(addressNew.uid);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Perfil', 4000);
      });
    // }
  }
  updateAddress(address) {
      this._addresService.updateAddress(address).then(() => {
        this.snack.launch('Dirección actualizada', 'Perfil', 4000);
      })
      .catch(error => {
        this.snack.launch('Error: ' + error.message, 'Perfil', 4000);
      });

  }

  onChangesCountry() {
    this.addressForm.get('country').valueChanges.subscribe(camb => {
      if (camb) {
        const country = this._addresService.getCountry(camb);
        country.valueChanges().subscribe(count => {
          if (count) {
            for (const county of count) {
              const countryData = county;
              this._addresService.getStates(countryData).valueChanges().subscribe(states => {
                this.states = states;
              });
            }
          }
        });
      }
    });
  }

  updateUser(id: string) {
    this._userService.updateAddress(id);
  }

}

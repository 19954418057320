import { Component, OnInit, Input } from '@angular/core';
import { CategoryService } from '@common/category.service';
import {MatChipsModule} from '@angular/material/chips';
import { SubcategoryService } from '@common/subcategory.service';


@Component({
  selector: 'app-expansion-subcats',
  templateUrl: './expansion-subcats.component.html',
  styles: []
})
export class ExpansionSubcatsComponent implements OnInit {
  @Input() id;

  subcats;

  constructor(
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService
  ) {
    this.subcats = this.subcategoryService.getCategorySubcategories(this.id);

    // this.subcats = this.categoryService.getCategorySubcategories(this.id).collection('subcategories').snapshotChanges().map(actions => {
    //   return actions.map(subcategory => {
    //     const subcats = subcategory.payload.doc.data();
    //     return{...subcats};
    //   });
    // });
  }

  ngOnInit() {
  }

}
